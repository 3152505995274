import React, {useRef, useEffect, useState} from "react";
import { getInstanceByDom, init} from "echarts";
import type {ECharts} from "echarts";
import {TsReactEchartsProps} from "./props/ts-react-echarts-props";
import {colorPalette, LinePlotData, MarkedAreaData} from "./props/time-series";
import { rangeArray, ZoomLevel} from "./../../LineChart/utils";
import {getWeldingTimeSeries, getLabelData, Labels} from "./../../LineChart/api";
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {useEffectOnce} from "../UseEffectOnce/useEffectOnce";
import exp from "constants";


function ReactEchartsLinechart({
                                   option,
                                   settings,
                                   loading,
                                   theme,
                                   weldingID,
                                   pointPercentage,
                                    dynamicPoint,
                                     height,
                                    topMargin,
                                    animation,
                                    experimentID,
                               }: TsReactEchartsProps): JSX.Element {

    const chartRef = useRef<HTMLDivElement>(null);
    const waitTillReload: number = 800
    const dataZoomSubject = new BehaviorSubject<any>(null);
    const [linePlot, setLinePlot] = useState<LinePlotData>()
    const [labelData, setLabelData] = useState<Labels>([])
    const [timeSeriesLength, setTimeSeriesLength] = useState<number>(0)

   
    const  currentExperiment = window.localStorage.getItem("currentExperiment");
    const currentWelding = window.localStorage.getItem("currentWelding");

    useEffect(()=>{
        if (weldingID !== -1 && experimentID !== -1 && chartRef.current !== null) {
            localStorage.setItem("currentExperiment", experimentID.toString())
            localStorage.setItem("currentWelding", weldingID.toString())

            var chart = getInstanceByDom(chartRef.current);

            if (chart !== undefined) {

                const start = 0
                const end = 100

                chart.setOption({
                    dataZoom: {
                        type: 'inside',
                        start: start,
                        end: end,
                    }
                })

                const option: any = chart.getOption()

                var zoomLevel: ZoomLevel = new ZoomLevel(start, end);
                let timeAxis = option.xAxis[0].data;
                let zoom = zoomLevel.convertToZoom(timeAxis)
                chart.showLoading();
                let startEnd: [number, number] = zoom.getExtendedZoomIndex();
                getWeldingTimeSeries(Number(weldingID), experimentID , undefined, undefined)
                    .then((data: LinePlotData | any) => {
                        setLinePlot(data);
                        chart?.setOption(option);
                        chart?.hideLoading();
                    });
            }}
    },[weldingID, experimentID])

    useEffect(() => {
        if (dynamicPoint !== -1 && chartRef.current !== null) {
            getZoomOnPoint(dynamicPoint)
        }
    }, [dynamicPoint]);

    useEffect(() => {
        if (animation !== -1 && chartRef.current !== null) {
            //Funktionell gesehen nicht notwendig, wird nur dazu enutzt um line chart zu bewegen
            getAnimationOnPoint(animation)
        }
    }, [ animation]);

    useEffect(() => {
        if (pointPercentage !== undefined && chartRef.current !== null) {
            getZoomOnPoint(pointPercentage)
            console.log("Point for zoom: ", pointPercentage);
        }
    }, [pointPercentage]);

    /**
     * getZoomOnPoint takes a static or dynamic point and downloads the new data
     */
    const getZoomOnPoint = (point: number) => {
        if(chartRef.current !== null && point !== -1 && point !== undefined && weldingID  !==  -1 && experimentID !==  -1){
            var chart = getInstanceByDom(chartRef.current);
            if (chart !== undefined) {
            const start = (point - 0.01)*100
            const end = (point + 0.01)*100
            if(start !== -1 && end !== -1){

            chart.setOption({
                dataZoom: {
                    type: 'inside',
                    start: start,
                    end: end,
                }})

            const option: any = chart.getOption()

            var zoomLevel: ZoomLevel = new ZoomLevel(start, end);
            let timeAxis = option.xAxis[0].data;
            let zoom = zoomLevel.convertToZoom(timeAxis)
            chart.showLoading();
            let startEnd: [number, number] = zoom.getExtendedZoomIndex();
            getWeldingTimeSeries(Number(weldingID), experimentID , startEnd[0], startEnd[1])
                .then((data: LinePlotData | any) => {
                    setLinePlot(data);
                    chart?.setOption(option);
                    chart?.hideLoading();
                });
        }}}
    }

    /**
     * Beim Bewegen der Punkte die Bewegung am Linechart anzeigen
     */
    const getAnimationOnPoint = (point : number) =>{
        if(chartRef.current !== null && point !== -1 && point !== undefined){
            var chart = getInstanceByDom(chartRef.current);
            if (chart !== undefined) {
                const start = (point - 0.01)*100;
                const end =(point + 0.01)*100;
                if(start !== -1 && end !== -1) {
                    chart.setOption({
                        dataZoom: {
                            type: 'inside',
                            start: start,
                            end: end,
                        }
                    })
                }

        }}
    }


    const dataZoomFunction = (dataZoomObj: any) => {
        if (chartRef.current !== null && dataZoomObj !== null && currentExperiment !== null) {
            // let chart = getInstanceByDom(chartRef.current);
            const chart = getInstanceByDom(chartRef.current);
            if (chart !== undefined) {
                //console.log("dataZoom", dataZoomObj);
                let start: number;
                let end: number;

                if (dataZoomObj.hasOwnProperty("batch")) {
                    start = dataZoomObj.batch[0].start
                    end = dataZoomObj.batch[0].end
                } else if (dataZoomObj.hasOwnProperty("start") && dataZoomObj.hasOwnProperty("end")) {
                    start = dataZoomObj.start
                    end = dataZoomObj.end
                }else {
                    start = 0
                    end = 100
                }

                var zoomLevel: ZoomLevel = new ZoomLevel(start, end);

                const option: any = chart.getOption();
                let timeAxis = option.xAxis[0].data;
                let zoom = zoomLevel.convertToZoom(timeAxis)

                chart.showLoading();
                let startEnd: [number, number] = zoom.getExtendedZoomIndex();
                if(startEnd[0] !== undefined && startEnd[1] !== undefined)
                    getWeldingTimeSeries(Number(currentWelding), parseInt(currentExperiment), startEnd[0], startEnd[1]).then((data: LinePlotData | any) => {
                        setLinePlot(data);
                        chart.setOption(option);
                        chart.hideLoading();
                    });
            }
        }
    };

    dataZoomSubject?.pipe(
        debounceTime(waitTillReload)
    ).subscribe(dataZoomFunction)

    function downloadWeldingTimeSeries() {
        //console.log("Download New Data");
        if(weldingID !==  -1 && experimentID !== -1) {
            getWeldingTimeSeries(Number(weldingID), experimentID, undefined, undefined).then((data: LinePlotData[] | any) => {
                data.isMainTimeSeries = true;
                setLinePlot(data);
            }).catch((error: any) => {
                console.log(error);
            });
        }
    }



    useEffectOnce(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
            setLabelData([]);
            downloadWeldingTimeSeries();
        }

        const resizeChart = () => {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        chart?.on("datazoom", (event) => {
            // console.log("Zoom triggered");
            dataZoomSubject.next(event);
        });

        chart?.on("brush", (event) => {
            console.log(event);
        });

        chart?.setOption(option);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    });


    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (chart !== undefined) {
                loading === true ? chart.showLoading() : chart.hideLoading();
            }
        }
    }, [loading]);

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart: ECharts | undefined = getInstanceByDom(chartRef.current);
            if (linePlot !== undefined && linePlot !== null
                && chart !== undefined && chart !== null) {

                let option = {
                    series: linePlot.timeSeries,
                    xAxis: {
                        data: rangeArray(0, Math.max(...linePlot.timeStamp.data))
                    },
                    yAxis: [
                        {
                            position: 'left',
                            name: "voltage",
                        },
                        {
                            position: 'right',
                            name: "current",
                        },
                    ]
                }
                chart?.resize();
                chart?.setOption(option);
                chart?.hideLoading();
                if (labelData !== undefined && labelData !== null && labelData.length > 0) {
                    mapLabels(chart, labelData);
                }
            }
        }
    }, [linePlot]);

    const mapLabels = (chart: ECharts, labelData: Labels) => {
        //console.log(" ---- Map labels -----");
        const oldOption: any = chart.getOption();
        let labels: MarkedAreaData[] = [];
        let colorMap = new Map<string, number>();
        let colorCounter: number = 0;
        for (let label of labelData) {
            if (!colorMap.has(label.label)) {
                colorMap.set(label.label, colorCounter);
                colorCounter++;
            }

            let start = label.start;
            let end = label.end;

            // console.log("start end", start, end);
            if (start !== -1 && end !== -1) {
                // @ts-ignore
                let selectedColor = colorPalette[colorMap.get(label.label)];
                selectedColor = (selectedColor === undefined) ? 0 : selectedColor;
                labels.push([{
                    name: label.label,
                    xAxis: start,
                    itemStyle: {color: selectedColor, opacity: 0.2, silent: true}
                }, {xAxis: end, itemStyle: {color: selectedColor, opacity: 0.2, silent: true}}]);
            }
        }

        //console.log("Labels", labels);
        // console.log("Labels", JSON.stringify(labels));

        let options = {
            series: [
                {
                    markArea: {
                        data: labels
                    }
                }
            ]
        }
        chart.setOption(options);
    }


    useEffect(() => {
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            if (linePlot !== undefined && linePlot !== null
                && labelData !== undefined && labelData !== null
                && chart !== undefined) {
                mapLabels(chart, labelData);
            }
        }
    }, [labelData]);

    return (
        <div>
            <div ref={chartRef} className="Line-chart" style={{ height: height, marginTop: `${topMargin}vh`, marginLeft: "-1vw"}}></div>
        </div>
    );
}


export {ReactEchartsLinechart};