import React, {useState} from "react";
import {Menu} from "antd";
import {LineChartOutlined} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import {getExperiments, getWeldingRunIds} from "../api";


type ChildItem = {
    key: string;
    label: string;
}

type MenuItem = {
    key: string;
    label:any;
    children: ChildItem[];
}

type MyProps = {
    setMenuItems: (item: [number, number]) => void
};

type MyState = {
    menuItems: MenuItem[];
    selectedItem: number;
    collapsed : boolean;
};

class SideMenu extends React.Component<MyProps, MyState> {

    state: MyState = {
        menuItems: [],
        selectedItem: 0,
        collapsed: true
    };


    componentDidMount() {
        // console.log("Did mount SideMenu");

        getExperiments().then((experiment_id: number[] | any) => {
            // console.log("experiment_id", experiment_id);
            this.setState((state) => ({
                menuItems: []
            }));
            experiment_id.forEach((experiment: number) => {
                getWeldingRunIds(experiment).then((measurements: number[] | any) => {
                    let childern: ChildItem[] = [];
                    measurements.forEach((measurement: number) => {
                        childern.push({
                            key: `${experiment}_${measurement}`,
                            label: `Welding ${measurement}`
                        })
                    });

                    let experiments: MenuItem = {
                        key: `sub_${experiment}`,
                        label: (<span>{experiment}  <LineChartOutlined /></span>),
                        children: childern,
                    }

                    this.setState((state) => ({
                        menuItems: [...state.menuItems, experiments]
                    }));

                }).catch((error: any) => {
                    console.log(error);
                });
            });
        }).catch((error: any) => {
            console.log(error);
        });
    }


    render () {
        return (
            <Sider collapsible={false} collapsed={this.state.collapsed} onCollapse={(value) => this.setState({ collapsed: value })}>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={['1_2']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    items={this.state.menuItems.sort((a, b) => Number(a.key.split("_")[1]) - Number(b.key.split("_")[1]))}
                    style={{ overflow: "auto" }}
                    onClick={(item) => this.props.setMenuItems([Number(item.key.split("_")[0]), Number(item.key.split("_")[1])])}
                />
            </Sider>
        )
    };
}


export {SideMenu};
