import React from "react";
import "../home.css";
import {ReactEchartsLinechart, ReactEchartsProps,
} from "./LineChart/line_chart";
import { Breadcrumb, Layout, message } from "antd";
import lineChartOptions from "./LineChart/line-chart-options";
import { SideMenu } from "./LineChart/menu/side-menu";
;

const { Header, Content } = Layout;

type TimeseriesProps = {
    selectedWeldingIDldingID: number[],
    setSelectedWeldingID: (item: [number, number]) => void,
};


export function Timeseries({ selectedWeldingIDldingID, setSelectedWeldingID}: TimeseriesProps) : JSX.Element{

    //@ts-ignore
    const option: ReactEchartsProps["option"] = lineChartOptions();

    return(
        <Layout style = {{borderLeft: '1px solid #000'}}>
            <SideMenu setMenuItems={setSelectedWeldingID}></SideMenu>
            <Layout className="st-layout">
                <div style={{ position: 'relative' }}>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item>Experiment {selectedWeldingIDldingID[0]}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Welding Measurement {selectedWeldingIDldingID[1]}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Content className="main-content">
                    <ReactEchartsLinechart option={option} weldingID={selectedWeldingIDldingID[1]} experimentID={selectedWeldingIDldingID[0]} messageApi={message}/>
                </Content>
            </Layout>
        </Layout>
    )
}