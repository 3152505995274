import React, {useRef, useEffect, useState, CSSProperties} from "react";
import { Select, Space} from 'antd';
import { getEmbeddingList } from "./api";

type SelectorProps = {
    selectedEmbd: number
    setSelectedEmbd: (value: number) => void
}

type EmbeddingList = {
    id: number
    name: string
}


export function Selector({selectedEmbd, setSelectedEmbd}: SelectorProps) : JSX.Element{


    const [listEmbd, setListEmbd] = useState<EmbeddingList[]>([])

    useEffect(() => {
        getEmbeddingList().then((embedding_list: any) => {
            // console.log("embedding_list", embedding_list);
            setListEmbd(embedding_list)
        })
    }, []);

    const convertLabel = (name: string) => {
        return <div><em> Embedding Map:</em> {name}</div>;
    }

    return(
        <div className="FillParent">
            <Space className="FillParent" direction="vertical">
                <Select 
                className="Selector"
                defaultValue= {selectedEmbd} 
                onChange={(value: number) => {setSelectedEmbd(value)}} 
                options={listEmbd.map((embedding: EmbeddingList) => ({ 
                    label: convertLabel(embedding.name), value: embedding.id 
                    }))}>
                </Select>
            </Space>
        </div>
    )
}
