/**
 * Set the Starting Box again
 */
export function setStartingBox(){
    var datapoints: number[][] = [[54, 15], [46, 15]];
    return[{//Rahmen um Start und Endpunkt legen in Abhängigkeit der initialen Punkte
            id: 'frame1',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[0][0] + 4, datapoints[0][1] - 2],
                [datapoints[1][0] - 4, datapoints[0][1] - 2]
            ],
        }, {
            id: 'frame2',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[1][0] - 4, datapoints[0][1] + 5],
                [datapoints[0][0] + 4, datapoints[0][1] + 5]
            ],
        }, {
            id: 'frame3',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[0][0] + 4, datapoints[0][1] - 2],
                [datapoints[0][0] + 4, datapoints[0][1] + 5]
            ],
        }, {
            id: 'frame4',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[1][0] - 4, datapoints[0][1] - 2],
                [datapoints[1][0] - 4, datapoints[0][1] + 5]
            ],
        }]
}
/**
 * "Löschen" durch ändern der Optionen von Line zu Scatter ohne Icon
 */
export function removeStartingBox(){
    return [{
             id: 'frame1',
            type: 'scatter'
        },
            {
            id: 'frame2',
            type: 'scatter'
        }, {
            id: 'frame3',
            type: 'scatter'
        },{
            id: 'frame4',
            type: 'scatter'
        }]
}

/**
 * Optionen für das setzen der Labels bei jedem Punkt im xyArray und angabe eines Prozentsatzes für diesen Punkt
 */
export function createLabelArray(xyArray : number[][], pointNames: string[], percent : number[][]){
    var array : any = []
    for(let i : number = 0 ; i < xyArray.length; i++){
        array.push({
            value: [xyArray[i][0], xyArray[i][1]],
            label: {
                position: 'top',
                distance: 27,
                show: true,
                formatter: [
                    `{textBig|${pointNames[i]}  }\n`+ '{text|'+Math.round(percent[i][1])+' %}'
                ].join('\n'),
                backgroundColor: '#eee',
                borderColor: '#000',
                borderWidth: 2,
                borderRadius: 3,
                padding: 3,
                shadowBlur: 3,
                shadowColor: '#888',
                shadowOffsetX: 0,
                shadowOffsetY: 3,
                color: '#000',
                rich:{
                    textBig: {
                        fontSize: 15 ,
                        color: '#000',
                        textBorderColor: '#000',
                        textBorderWidth: 1,
                        padding: 2
                    },
                    text: {
                        fontSize: 15,
                        padding: 3

                    }
                }
            }
        })
    }
    return array
}