import React, {useRef, useEffect, useState, CSSProperties} from "react";
import {ReactScatterChart} from "./scatter-plot";
import { Layout, message, Col, Row, Collapse, Divider, Space, Tour } from "antd";
import type { TourProps } from 'antd';

import {
  InfoCircleOutlined
} from '@ant-design/icons';
import { Selector } from "./selector";
import { Selector_Feature } from "./selector-feature";
import ExplanationText from "./explanation-text";
type EmbeddingViewProps = {
    
}

const { Panel } = Collapse;

export function EmbeddingView({ }: EmbeddingViewProps) : JSX.Element{

    const [selectedEmbd, setSelectedEmbd] = useState<number>(0)
    const [selectedFeat, setSelectedFeat] = useState<number>(0)
    const [openTour, setOpenTour] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const refSelectEmb = useRef<HTMLDivElement>(null);
    const refSelectColor = useRef<HTMLDivElement>(null);
    const refEmbedView = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // write count to storage
      const count = localStorage.getItem('tourCount');
      if (count === null) {
        localStorage.setItem('tourCount', '0');
        setOpenTour(true);
      } else {
        const countInt = parseInt(count);
        if (countInt < 2) {
          localStorage.setItem('tourCount', (countInt + 1).toString());
          setOpenTour(true);
        } 
      }
    }, [])


    const steps: TourProps['steps'] = [
      {
        title: 'Explanation of the Embedding View',
        description: 'Expand if you want to find out how the embedding is created. Click again to close it.',
        target: () => ref.current!,
      },
      {
        title: 'Select Embedding Map',
        description: 'Select the method of projecting the embedding into the two dimensional space.',
        target: () => refSelectEmb.current!,
      },
      {
        title: 'Select Color Map',
        description: 'Select the color Map which is used for the data points of the embedding.',
        target: () => refSelectColor.current!,
      },
      {
        title: 'Embedding View and Sample of One Cycle',
        description: 'Two dimensional representation of the latent space of the VQ-VAE is shown onm the left. Each point represents a cycle. on the right you can see the selected cycle. The time series shows current and voltage over time.',
        target: () => refEmbedView.current!,
      },
    ];


    const optionScatter: any = {
        animationDuration: 0,
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'cross'
          },
          formatter: function(params: any) {
            if (selectedFeat == 0) {
              if (params.color == 'green') {
                return 'Good Quality';
              } else if (params.color == 'orange') {
                return 'Bad Quality';
              } else {
                return params
              }
            } else if (selectedFeat == 1) {
              if (params.color == 'blue') {
                return 'No short circuit';
              } else if (params.color == 'green') {
                return 'Short short circuit';
              } else if (params.color == 'yellow') {
                return 'Medium short circuit';
              } else if (params.color == 'red') {
                return 'Long short circuit';
              } else {
                // console.log(params.color);
                return params
              }
            } else {
              return params
            }
          }
      },
      grid: [
        {
          top: '20%',
          right: '20%'
        },
        {
          bottom: '82%',
          right: '20%'
        },
        {
          top: '20%',
          left: '82%'
        }
      ],
      xAxis: [
        {
          scale: true,
          gridIndex: 0,
          axisLine: false,
        },
        {
          scale: true,
          type: 'category',
          show: false,
          axisTick: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
          gridIndex: 1
        },
        {
          scale: true,
          gridIndex: 2,
          show: false,
          axisLine: { show: false },
          axisTick: { show: false },
        }
      ],
      yAxis: [
          {
            scale: true,
            gridIndex: 0,
            axisLine: false
          },
          {
            scale: true,
            gridIndex: 1,
            show: false,
          },
          {
            scale: true,
            type: 'category',
            axisTick: { show: false },
            axisLabel: { show: false },
            axisLine: { show: false },
            gridIndex: 2
          }
        ],
        series: [
        ]
    };


    return(
        <Layout className="EmbeddingView">
          <Row>
          <div ref={ref} className="FillParent">
            <Collapse className="FillParent">
              <Panel  className="ExplanationPanel" header=
              {
               
                  <Space size={'small'}>
                    <InfoCircleOutlined />
                      What do we see here?
                    </Space>
              } key={1}>
              <Row>
                <Col span={14}>
                  <video controls loop autoPlay className="Animationvideo">
                    <source src="VQVAE_Animation.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Col>
                <Col span={10}>
                  <ExplanationText></ExplanationText>
                </Col>
              </Row>
              </Panel>
            </Collapse>
          </div>
          </Row>
          <Row>
            <Col span={12}>
                <Divider>Embedding View</Divider>
            </Col>
            
            <Col span={12}>
                <Divider>Selected Welding Sample</Divider>
            </Col>
          </Row>
         
            <Row>
           
              <Col span={6}>
              <div ref={refSelectEmb} className="FillParent">
                <Selector selectedEmbd={selectedEmbd} setSelectedEmbd={setSelectedEmbd}/>
              </div>
              </Col>
              <Col span={6}>
              <div ref={refSelectColor} className="FillParent">
                <Selector_Feature selectedFeat={selectedFeat} setSelectedFeat={setSelectedFeat}/>
                </div>
              </Col>
            </Row>
          
          <div ref={refEmbedView} className="EmbeddingView">
            <ReactScatterChart option={optionScatter} selectEmbd={selectedEmbd} selectFeat={selectedFeat} messageApi={message} />
          </div>
          <Tour
            open={openTour}
            onClose={() => setOpenTour(false)}
            steps={steps}
            // @ts-ignore
            indicatorsRender={
              (current: number, total: number) => 
              (
                  <span>
                    {current + 1} / {total}
                  </span>
              )
            }
          />
        </Layout>

    )
}

export default EmbeddingView;