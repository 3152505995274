const dataV: number[] = [];
const dataC: number[] = [];

const lineChartOptions = () => { return {
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['current', 'voltage']
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    xAxis: {
        type: 'category',
        data: [],
        boundaryGap: false,
        // axisLine: { onZero: false },
        // splitLine: { show: false },
        // min: 'dataMin',
        // max: 'dataMax',
        axisPointer: {
            z: 100
        }
    },
    yAxis: [
        {
            type: 'value',
            position: 'left',
            name: "voltage"
        },
        {
            type: 'value',
            position: 'right',
            name: "current"
        },
    ],
    dataZoom: [
        {
            show: true,
            rangeMode: 'value',
        },
        {
            type: 'inside',
            rangeMode: 'value',
            // zoomOnMouseWheel: 'ctrl',
            moveOnMouseMove: 'ctrl',
            moveOnMouseWheel: 'ctrl',
            preventDefaultMouseMove: false
        }
    ],
    brush: {
        toolbox: ['lineX', 'keep', 'clear'],
        xAxisIndex: 0
    },
    series: [
        {
            name: 'voltage',
            type: 'line',
            showSymbol: false,
            data: dataV
        },
        {
            name: 'current',
            type: 'line',
            showSymbol: false,
            data: dataC
        },
    ]
}};

export default lineChartOptions;