import React, { useRef, useEffect, useState } from "react";
import {getInstanceByDom, init} from "echarts";
import type { ECharts } from "echarts";
import { ReactEchartsProps } from "./react-echarts-props";
import "rsuite/dist/rsuite.min.css";
import { theme,  notification, Button,  Space, Spin, Modal} from 'antd';
import {calculatePercentages, imageDimensions, scaled, getCoordDimension, preventSwitchingIDs, createLineFunction} from "../Calculations/calculations";
import { selectImage, sendDatapointsForImage, searchfordata, deletefile} from "../API/api";
import {removeStartingBox, createLabelArray, setStartingBox} from "./changeOptions";
import './styles.css'
import {TSMapper} from "../TSMapper/TSMapper";
import {findDifferentElement} from "../Calculations/calculations";
import {BehaviorSubject, pipe} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ShowDifferentButtons} from "../Buttons/showDifferentButtons"
import {restoreState} from "./restoreState"
import {cleanReset} from  "./cleanReset"
import {lineCreation} from "./createLine";



function ImageMapper({
                                  option,
                                  style,
                                  settings,
                                  loading,
                                  imageBackendID,
                                  experimentAndWeldingID,
                                  username,
                                  allowDataLoad,
                              }: ReactEchartsProps): JSX.Element {

    const chartRef = useRef<HTMLDivElement>(null);
    const arrayRef = useRef<number[][]>([]) //useRef speichert Zustand des Objektes ein, ähnlich wie "useState" nur das nicht neu gerendered wird und Zugriff mit ".current"
    const ImageSizeRef = useRef<number[]>([100,100]) //Weite x Höhe
    const dynamicPointSubject = new BehaviorSubject<any>(null); //Punkte der tatsächnlich für das Laden der neuen Datenpunkte verwendet wird
    const animatedDraggingSubject = new BehaviorSubject<any>(null);//In sehr kleinen Abständen um Schiebebewegung zu animieren
    const actualDisplaySizeSubject = new BehaviorSubject<any>(null);//Funktion für berechnung der größe des Bildschrimes nicht so oft aufrufen
    const [api, contextHolder] = notification.useNotification();
    /**
    *Zustände initialisieren
    */
    const [back, handleBack] = useState('initial'); //Zustand "Back" um nach setzen der labels einen schritt zurück gehen zu können
    const [showBack, showBackButton] = useState('initial'); //Zustand "showBack" um Back-Button nur dann dem Nutzer anzuzeigen, wenn "ready" geklickt wurde
    const[ createLine, setCreate] = useState('initial');//Zustand "createLine" um nach klicken von "Create"-Button die Linie+Zusätzliche Punkte zu erzeugen
    const [initiateCreate, setInitiate] = useState('initial'); //Zustand "initiateCreate" um "Create"-Button erst nach herausziehen der Punkte aus der Startbox anzuzeigen
    const [box, setDeleteBox] = useState('initial');//Zustand "Box" um Startbox in der die Start/End Punkte plaziert werden zu entfernen
    const [forceRestart, setForcedRestart] = useState('initial');//Erzwinge Neustart nach Fehler beim rausziehen
    const [refresh, setRefresh] = useState('initial'); //Zustand für Refresh, um Problem von gefrorenen Punkten auf der Linearen Funktion zu lösen
    const [windowSize, setWindowSize] = useState(getWindowSize()); //Zustand um Punkte mit Window size zu ändern
    const [imageURLs, setImageURLs] = useState<string[]>([]) //initial kann auch per default erst bestimmtes Bild hier eingesetzt werden
    const [saveURL, setURL] = useState("initial") //URL des Bildes speichern für dynamische Berechnungen der Bildgröße
    const [imageWidth, setImageWidth] = useState<number>(0) //Abspeichern der "display" Bildweite
    const [imageHeight, setImageHeight] = useState<number>(0)//Abspeichern der "display" Bildweite
    const [savedPercentage, setPercentage] = useState<number[][]>([])//Alle Prozente für die Time Series Speichern
    const [pointForTS, setPointForTS] = useState<number>()//Porzent für ausgewählten Punkt für TSMapper komponente Speichern
    const [dynamicPoint, setDynamicPoint] = useState<number>(-1)//Zustand für Dynamischen Punkt
    const [isInitialized, setInitialised] = useState<string>("no")//Kontrolliere ob Chart bereits initialisert
    const [coordSystem, setCoordSystem] = useState<number[]>([0,0]) //Koordinatensystem größe in Pixeln
    const [topMargin, setTopMargin] = useState<number>(-10)//Margin um TSMapper nach rausziehen der Box hochzusetzen
    const [animation, setAnimatedPoint] = useState<number>(-1)//Zustand um weiterhin darzustellen das beim bewegen eines Punkte sich unabhängig vom tatsächlichen Zoom der linechart bewegt
    const [triggerReset, setReset] = useState<number>(0)
    const [restoreLine, setLineRestorer] =  useState<number>(0)
    const [savedLastSeries, setLastSeries] =  useState<any>()
    const [xyPositionInChart, setxyPositionInChart] = useState<number[]>([0,0])
    const [changeMargin,  setMargin] = useState<number>(0)
    const [fixedMagnifingGlass, fixMagnifingGlass] = useState<number>(0)
    const [isClicked, setIsClicked] = useState(false);
    const [showMagnify, setShowMagnify] = useState(false);
    const [magnifyPosition, setMagnifyPosition] = useState({ x: 0, y: 0 });

    const cachedImage = window.localStorage.getItem("cachedImage");
    const cachedID = window.localStorage.getItem("cachedID");
    /**
     * Delete data in backend if user request it, only for the selected image!
     */
       function handleDelete(){
        deletefile(username, experimentAndWeldingID[0], imageBackendID).then(() => {
            if (chartRef.current !== null) {
                setCreate("initial")
                setInitiate("initial")
                setLineRestorer(0)
                setDeleteBox("initial")
                const chart = getInstanceByDom(chartRef.current);
                const series: any = option?.series
                const resetProps = {chart, series, graphicManipulation,}
                cleanReset(resetProps)
                setTopMargin(-10)

            }
        }
    )
    }


    /**
     * prevent timeseries element being shifted over box
     */
    useEffect(()=>{
        setTopMargin(-10)
    }, [experimentAndWeldingID, changeMargin])
    /**
     * Functions for magnifing glass
     * @param event
     */
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsClicked(true);//necessary for preventing the magnifing glass to alwways be visible
    };

    const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsClicked(false);
        setShowMagnify(false);
    };


    /**
     * Function for calculating where exactly the picture needs to be zoomed in, coordinates are given to the state wich is used in the magnifing elementt
     *
     * @param event
     */
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (isClicked) {
            const divElement = event.currentTarget.querySelector(".image-div");

            if (divElement !== null) {
                if(chartRef.current) {
                    const chartInstance = getInstanceByDom(chartRef.current)
                    const series: any = option?.series

                    if(chartInstance!== undefined) {
                        const chartWidth = chartInstance.getWidth()
                        const middleOfPicture = chartWidth/2
                        var leftBorder = (middleOfPicture)-(imageWidth/2)
                        const coordSys : number[] = getCoordWidthxHeight()
                        const topBorder = (chartInstance.getHeight() - (coordSys[1]+10))/2
                        var x = ((xyPositionInChart[0]-leftBorder)/(imageWidth))*100
                        var y = ((xyPositionInChart[1]-topBorder)/(imageHeight))*100
                        setShowMagnify(true); //Needs to be set true to show the glass when mouse is getting moved

                        if(y>100||y<0 || x<0 || x>100){//prevent magnifingy  glass from showing when pointer outside the picture
                        setShowMagnify(false)
                        }

                        if (series[0].data.length === 5 && savedLastSeries!== undefined && fixedMagnifingGlass ===1) {//Assure that magnfiy glass is only movable in line direction
                            let steigung: number = ((100-savedLastSeries[4][1]) - (100-savedLastSeries[0][1])) / (savedLastSeries[4][0] - savedLastSeries[0][0])
                            let bConstant: number = ((100-savedLastSeries[0][1]) - steigung * savedLastSeries[0][0])
                            var y_adj : number = steigung *x + bConstant
                            if(x>50){
                                x += 12.5*((x-50)/50)
                            }

                            if(x<50){
                                x  -=12.5*(50-x)/50
                            }

                            if(y_adj>50){
                                y_adj += 12.5*((y_adj-50)/50)
                            }

                            if(y_adj<50){
                                y_adj  -=12.5*(50-y_adj)/50
                            }
                            y= y_adj

                            //Erknenntis: für die berechnung von y ist relevant, das die x koordinaten jeweils die maximalausdehnung  des bildes haben (dann wäre genau)
                            //wenn abweichend von diagonale muss gefunden werden  wie viel die verschiebung ist



                            setMagnifyPosition({ x, y });
                            return

                        }


                        //HERE: Workaround for 12.5 Percent Margin on all sides, needs to be added in linear way
                        if(x>50){
                            x += 12.5*((x-50)/50)
                        }

                        if(x<50){
                            x  -=12.5*(50-x)/50
                        }
                        if(y>50){
                            y += 12.5*((y-50)/50)
                        }

                        if(y<50){
                            y  -=12.5*(50-y)/50
                        }


                        setMagnifyPosition({x, y });
                    };
                }
            }
        }
    };

    const fixingMagnifingGlass = (value: number) => {
        if (fixedMagnifingGlass === 1) {
            fixMagnifingGlass(0)
        }else{
            fixMagnifingGlass(1)
    }
    };

    function positionApproximated(value:number){
        if(value<50){
            return -(12.5*(50-value)/50)
        }else if(value>50){
            return +(12.5*(value-50)/50)
        }else{
            return 0
        }
    }
    /**
     * Nexessary function to save the xy coordinates of the mouse wich are usable inside the chart
     * @param event
     */
    const mouseObserverInChart = (event: React.MouseEvent<HTMLDivElement>) => {
        if(chartRef.current) {
            const chartInstance = getInstanceByDom(chartRef.current)
           if(chartInstance!== undefined) {
               if (chartInstance) {
                   const x = event.nativeEvent.offsetX; // get the X coordinate of the mouse pointer relative to the chart container
                   const y = event.nativeEvent.offsetY; // get the Y coordinate of the mouse pointer relative to the chart container
                   setxyPositionInChart([x,y])
               }
           }

        }
    };

    /**
     * Höhe des Containers für das Hintergrundbild von der Bildschirmgröße abhängig festlegen, Parameter auch für die Berechnungen nutzbar
     * @param ImageYSize: kann beliebig verändert um die größe des Bildes zu bestimmen, wichtig ist nur darauf zu achten das bei vollbild der obere rand des koordinatensystems genau am oberen rand des bildes liegt
     * Bemerkung: Weite des Bildes verändert sich automatisch je nachdem wie die höhe verändert wird, muss nicht beachtet werden, hier nur auf LeftMargin achten falls sich das layout verändert (sollte aber eig immer 10% betrage)
     */
    const ImageYSize = windowSize.innerHeight*0.43
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100); //um Zoom zu berückscihtigen

    /**
     * Clean Reset if user changes browser size, loads new picture, changes zoom settings etc to guarantee correct functions
     */
    useEffect(()=>{
         if (chartRef.current !== null && isInitialized !== "no") {
             setCreate("initial")
             const chart = getInstanceByDom(chartRef.current);
             const series: any = option?.series
             if (series[0].data.length === 5) {
                 const  resetProps ={chart, series, graphicManipulation,}
                 cleanReset(resetProps)
             }
            if (restoreLine === 1 && savedLastSeries !== undefined) {
                const props = {chart, setCreate, setDeleteBox, setInitiate, handleBack, setTopMargin, option, savedLastSeries, savedPercentage, imageHeight, imageWidth, ImageYSize, windowSize, graphicManipulation, getCoordWidthxHeight, refreshFunction};
                restoreState(props);
                refreshFunction()
             }
         }
    },[ window, browserZoomLevel, triggerReset, imageHeight, imageWidth])

    /**
     * Bei auswahl eines Bildes im Sidemenu (imageID) entsprechendes Bild mit der funktion "getSlecttedImage" aus API hochladen
     */
    const showLoadingModal = () => {
        Modal.info({
            title: 'Loading',
            content: (
                <div>
                    <Spin size="large" />
                    <p>Loading content...</p>
                </div>
            ),
            maskClosable: false, // Option, um das Modal nur durch Klicken auf die Schaltfläche zu schließen
        });
    };

    const hideLoadingModal = () => {
        Modal.destroyAll();
    };

    useEffect(()=>{


        showLoadingModal()
            if (imageBackendID !== undefined && imageBackendID !== -1) {
                getSelectedImage(imageBackendID)
            } else if (imageBackendID === -1 && cachedID === null) {
                getSelectedImage(76)
            }
            hideLoadingModal()
    },[imageBackendID, allowDataLoad])

    function getSelectedImage(id: number) {//anhand id Bild in backend auswählen
        setLineRestorer(0)



        selectImage(id, experimentAndWeldingID[0]).then((value: string[]) => { //TODO: string[] durch string ersetzen und TODO2: setzte richtige experiment ID ein!

            const img = new Image();
            img.src = value[0]
            img.addEventListener("load", () =>{
                setImageURLs(value)
                localStorage.setItem("cachedImage", `${[img.width, img.height]}`)
                if(id !== undefined) {
                    localStorage.setItem("cachedID", id.toString())
                }
            });

            setURL(value[0])//für berechnung der maße+position des Bildes und anpassung der chartMargin, seperat damit "kontolle" wann berechnung gemacht wird

            if(username !== '' && experimentAndWeldingID[0] !== -1) {
                searchfordata(username, experimentAndWeldingID[0], id).then((savedSeries: any) => { //TODO: string[] durch string ersetzen
                    if(savedSeries[id] !== undefined) {
                       setLastSeries(JSON.parse(savedSeries[id].data))
                       setLineRestorer(1)
                       setReset(+1)
                   }
                }).catch(e => console.log(e))
            }
        }).catch(e => console.log(e))
        hideLoadingModal()
    }



    /**
     * Immer aktuellste Displaybreite und Displayhöhe des eingesetzten Bildes berechnen
     */

    useEffect(()=>{
        //actualDisplaySizeSubject.next(saveURL)
        actualImageDisplaySize(saveURL)
    },[saveURL, windowSize, ImageYSize, browserZoomLevel, isInitialized])


    const actualImageDisplaySize = (newURL : string) => {
        const img = new Image();
        img.src = newURL;
        if(cachedID) {//Wenn bereits Bild ausgewählt wurde kann man prüfen ob es im cache ist
            const cachedIDstring = localStorage.getItem("cachedID")
            if (imageBackendID !== undefined && imageBackendID !== -1) {
                if (cachedImage && imageBackendID.toString() === cachedIDstring) { //Wenn im Cache + richtiges Bild ist das derzeit ausgewählt ist
                    var imageString: any = localStorage.getItem("cachedImage")
                    const cleanedString = imageString.replace(/[\[\]]/g, '');
                    const numbersArray = cleanedString.split(',');
                    const firstNumber = parseInt(numbersArray[0]);
                    const secondNumber = parseInt(numbersArray[1]);
                    let display_width = windowSize.innerWidth;
                    if (windowSize.innerWidth > (ImageYSize / secondNumber) * firstNumber) {
                        display_width = (ImageYSize / secondNumber) * firstNumber;
                    }
                    const display_height = (display_width / firstNumber) * secondNumber;
                    setImageWidth(display_width)
                    setImageHeight(display_height)
                    ImageSizeRef.current = [display_width, display_height]
                }
            }
        } else {

                    img.addEventListener("load", () => {
                        let display_width = windowSize.innerWidth;
                        if (windowSize.innerWidth > (ImageYSize / img.height) * img.width) {
                            display_width = (ImageYSize / img.height) * img.width;
                        }
                        const display_height = (display_width / img.width) * img.height;
                        setImageWidth(display_width)
                        setImageHeight(display_height)
                        ImageSizeRef.current = [display_width, display_height]
                    });
                }
    }
    actualDisplaySizeSubject?.pipe(
        debounceTime(1000)
    ).subscribe(actualImageDisplaySize)

    /**
     * Aktuelle Fensterhöhe und weite in einem extra Parameter "WindowSize"
     * Bemerkung: eigentlich sollte auch window.innerHeight und window.innerWidth nutzbar sein aber dies hat zu Problemen geführt
     */
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    type WindowSize = {
        innerWidth: number,
        innerHeight: number,
    }

    function getWindowSize() : WindowSize {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    //TODO: schauen  ob inittialisierung ausgelagert werden kann
    /**
     * Initialisierung des Charts
     */
    useEffect(() => { //Erinnerung: useEffect componentDidMount() und componentDidUpdate() kombiniert, z.B: für Zugriff auf einen Zustand wie hier mit ".current"
        if(imageBackendID !== undefined && allowDataLoad===true && username !== '' && experimentAndWeldingID[0]!== -1) {
            searchfordata(username, experimentAndWeldingID[0], imageBackendID).then((savedSeries: any) => { //TODO: string[] durch string ersetzen
                if (savedSeries !== undefined && savedSeries[imageBackendID] !== undefined) {
                    if(imageBackendID === -1 && cachedID=== null) {//Wenn nichts im cache dann default
                        setLastSeries(JSON.parse(savedSeries[76].data))
                    }else if(cachedID !== null && cachedID !== undefined){
                        setLastSeries(JSON.parse(savedSeries[cachedID].data))
                    } else{
                        setLastSeries(JSON.parse(savedSeries[imageBackendID].data))
                    }
                    setLineRestorer(1)
                    setReset(+1)
                }
            }).catch(e => console.log(e))
        }
        setReset(triggerReset + 1)
         if (isInitialized === "no") {
             setInitialised("yes")
         }
        setCreate('initial');
         if(restoreLine !== 1) {
             setTopMargin(-10);
         }

        setInitiate('initial');
        setDeleteBox('initial');
        setForcedRestart('initial');
        setRefresh('initial');
        showBackButton('initial');
        handleBack('initial');

         // Initialize chart
         let chart: ECharts | undefined;
         //setChartYsize((ImageYSize*1.9)+imageHeight*0.1)

         if (chartRef.current !== null) {
             chart = init(chartRef.current, theme, {width: window.innerWidth * 0.9}); //intialisierung mit dem dom "chartRef.current" und optinalem theme, gibt eine echartsInstance zurrück
         }

         // ResizeObserver is leading to a bit janky UX
         const resizeChart = () => { //Funktionsinitialisierung
             chart?.resize(); //resize chart, mit "echartsInstance.resize()
             const series: any = option?.series
             chart?.setOption(option)

            chart?.setOption({
                tooltip: {
                 triggerOn: 'mousemove', // set triggerOn option to 'mousemove'
                  formatter: (params: any) => {
                      return params;
                     },
                }});
             if (series !== undefined && chart !== undefined) {
                 var data: number[][] = series[0].data //Intialisierung eines Arrays "data" da nicht direkt auf seies[0].data zugegriffen werden kann
                 chart.setOption({
                     graphic: graphicManipulation(data, chart)
                 });
             }
         }
         window.addEventListener("resize", resizeChart);//Event listener vom typ resize bedeutet, das resizeChart immer dann aufgerufen wird wenn die ansicht im dokument sich ändert
         // Return cleanup function
         return () => {
             chart?.dispose(); //dispose() zerstört die echartsInstance, cleanup für ausführung des nächsten effekts
             window.removeEventListener("resize", resizeChart);
         };
    }, [option, settings]); //neuerstellunng immer wenn sich theme, option oder settings ändern//hier waren [option,settings]

    /**
     * Graphic Komponente manipulieren um punkte bewegen zu können
     */
    function graphicManipulation(data: any, chart: any){
        return( data.map((item: number[], dataIndex: number) => {
            return {
                type: 'circle',
                position: chart?.convertToPixel('grid', item),
                shape: {
                    cx: 0,
                    cy: 0,
                    r: 25 / 2
                },
                invisible: true,
                draggable: true,
                ondrag: (evt: any) => onPointDragging(chart, data, dataIndex, [evt.offsetX, evt.offsetY]), //offsetX und offsetY geben auf ein MouseEvent jeweils die X und Y Koordinate der Maus an
                onmousemove: () => {showTooltip(chart, dataIndex); /*handleMagnify(params)*/;},
                onmouseout: () => {hideTooltip(chart);
                    /*chart?.setOption({
                        graphic: null,
                    });*/},
                z: 100
            };
        }))
    }

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);

            const series: any = option?.series

            chart?.setOption(option)
            if (series !== undefined && chart !== undefined) {
                var data: number[][] = series[0].data
                chart.setOption({
                    graphic: graphicManipulation(data, chart)
                });
            }
        }
    }, [option, settings, createLine, forceRestart]);


    const showTooltip = (chart: any, dataIndex: number) => {
        chart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex
        });

    }

    const hideTooltip = (chart: any) => {
        chart.dispatchAction({
            type: 'hideTip'
        });
    }

    function waitForInitiate(){
        setInitiate('initiate')
        setTopMargin(-25);
    }

    /**
     * Neue Werte der verschobenen Punkte in die Optionen der Instanz einsetzen
     * Hier auch enthalten: Prüfung ob ein Punkt aus dem Koordinatensystem hinaus gezogen wird (Warnung ausgeben+ Reset), Reset initialisieren, bei 5 Punkten die Verschiebung linearisieren, dynamisch den jeweils verschobenen Punkt im Array erkennen und an den linechart als prop geben um dynamisch zu Zoomen
     */
   const onPointDragging = (chart: any, data: number[][], dataIndex: number, pos: number[][]) => {//Funktion um die neuen Daten durch Bewegen der Punkte in die Options des charts zu packen
        var realDataLengthArray : number[][] = data.filter(function(value) {//Muss bestimmt  werden werden dan splice empty slots verurusacht (undefined)
            return value !== undefined;
        });
       if (data[0][0] !== 54 && data[1][0] !== 46 && data[0][1] !== 15 && data[1][1] !== 15) {
            setTimeout(waitForInitiate, 2000); //create Button zeigen wenn punkte nicht mehr auf initialem stand, leicht verspäten da positionieren etwas dauert
            setForcedRestart('initial');
                chart?.setOption({//Box löschen
                    series: removeStartingBox()
                });
        }

        data[dataIndex] = chart.convertFromPixel('grid', pos); //die positionsdaten wieder als pixel konvertieren um dann zu updaten

        //Fange ab, falls nutzer versucht die Punkte ausserhalb des Bildes zu positionieren, erst dannach die daten aktualisieren
        if (realDataLengthArray.length === 2) {
            if (100 > data[0][0] && 0 < data[0][0] && 100 > data[1][0] && 0 < data[1][0] && 100 > data[0][1] && 0 < data[0][1] && 100 > data[1][1] && 0 < data[1][1]) {
                chart.setOption({
                    series: [{
                        id: 'draggablePoints',
                        data: data
                    }]
                });
            } else {
                alert("Sorry, positioning points outside the picture is not allowed. Points are now reset to initial position! If that doesn't happen automatically, please restart the application manually.")
            }
        } else if (realDataLengthArray.length === 5 &&  (100 > data[0][0] && 0 < data[0][0] && 100 > data[1][0] && 0 < data[1][0] && 100 > data[0][1] && 0 < data[0][1] && 100 > data[1][1] && 0 < data[1][1])) {
            setLineRestorer(1)
            let steigung: number = (data[4][1] - data[0][1]) / (data[4][0] - data[0][0])
            let bConstant: number = data[0][1] - steigung * data[0][0]


            for (let i = 0; i < data.length; i++) {
                data.splice(i, 1, [(data[i][0]), steigung * (data[i][0]) + bConstant]);
            }

            const linearDragging: number[][] = data


            const arrayString: string | null = localStorage.getItem("lastSeries");
            const lastArray: number[][] = arrayString ? JSON.parse(arrayString) : [];

            const draggedPointID: number = findDifferentElement(lastArray, linearDragging)//Identifiziere bewegten Punkt für TSMappe
                if (draggedPointID !== -1) {
                    let percent: number[][] = calculatePercentages(linearDragging)
                    //setDynamicPoint(percent[draggedPointID][0])
                    animatedDraggingSubject.next(percent[draggedPointID][0])
                    dynamicPointSubject.next(percent[draggedPointID][0])
                }

                const option = chart.getOption();
                var lastData: number[][] = option.series[0].data
                localStorage.setItem("lastSeries", JSON.stringify(lastData))
                arrayRef.current = lastData //letzte Daten speichern um damit vergleichen zu können
                if (draggedPointID !== -1) {
                    chart?.setOption({
                        series: [{
                            id: 'draggablePoints',
                            data: preventSwitchingIDs(linearDragging, draggedPointID, steigung, bConstant)
                        }]
                    });
                }

            refreshFunction()
            saveAndScale(lastData) //für erhalten während bereits zweiter schritt läuft
        }
    }

    const dynamicPointPipe = (point :  number)  =>{
        setDynamicPoint(point)
    }
    dynamicPointSubject?.pipe(
        debounceTime(900)
    ).subscribe(dynamicPointPipe)

    const animatedPointPipe = (point: number) =>{
       setAnimatedPoint(point)
    }
    animatedDraggingSubject?.pipe(
        debounceTime(10)
    ).subscribe(animatedPointPipe)


    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            if (chart !== undefined) {
                chart.setOption(option, settings);
            }

        }
    }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (chart !== undefined) {
                loading === true ? chart.showLoading() : chart.hideLoading();
            }
        }
    }, [loading]);

    /**
     *
     * Dynamisch die daten speichern um bei  resize restoren zu können, skalierung notwendig damit auf jedes koordinatensystem zu bild verhältnis anwendbar
     */
    const saveAndScale = (series: any) => {
        if (series !== null) {
            let coordSysWidthxHeight: number[] = getCoordWidthxHeight()
            let imageDimension: number[] = imageDimensions(coordSysWidthxHeight, imageHeight, imageWidth, ImageYSize, windowSize)//NaN fehler von hier?
            const imageHeightHigh = imageDimension[3]
            const imageHeightLow = imageDimension[2]
            const imageWidthHigh = imageDimension[1]
            const imageWidthLow = imageDimension[0]

            var xyArray: number[][] = []
            for (let i = 0; i < series.length; i++) {
                xyArray.push([series[i][0], series[i][1]])
            }

            const datenSkaliert: number[][] = []//erstelle "richtig" skaliertes array mit den daten
            for (let i = 0; i < xyArray.length; i++) {
                datenSkaliert.push([scaled(xyArray[i][0], imageWidthLow, imageWidthHigh), scaled(xyArray[i][1], imageHeightLow, imageHeightHigh)])
            }

            let percent: number[][] = calculatePercentages(datenSkaliert) //berechne prozente der punkte auf der linie
            setPercentage(percent)
            setLastSeries(datenSkaliert)
        }
    }

    /**
     * Datenpunkte erhalten, funktion wird nach Klicken von "Ready" Button aufgerufen
     * Ablauf: zunächst müssen die Maße des Bildes innerhalb des Koordinatensystems genau berechnet werden (imageHeightHigh, ...), dann entsprechend die Datenpunkte so skalliert werden als ob das Bild genau in einem 0-100 Koordinatensystem angepasst wäre, mit diesen daten dann prozente berechnen+json erstellen+ an api senden und die labels setzen
     *
     */
    type SendData={
        id: string,
        x: string,
        y: string,
        percent: string,
        scaled: string
    }

     function getDataAndSetLabels() {
        showBackButton('yes'); //Um 'Back' Button anzuzeigen
         setLineRestorer(1) //Um labels zu halten bis neues Bild geladen wird
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            const series: any = option?.series; //Zugriff auf Datenarray mit series[0].data["Einen der Punkte"]["Innerhalb eines Punktes x (0) oder y (1) koordinate auswählen"]

            let coordSysWidthxHeight : number[] = getCoordWidthxHeight()
            let imageDimension : number[] = imageDimensions(coordSysWidthxHeight, imageHeight, imageWidth, ImageYSize, windowSize)//NaN fehler von hier?
            const imageHeightHigh = imageDimension[3]
            const imageHeightLow = imageDimension[2]
            const imageWidthHigh = imageDimension[1]
            const imageWidthLow = imageDimension[0]

            var xyArray : number[][] = []
            for(let i = 0; i < series[0].data.length; i++){
                xyArray.push([series[0].data[i][0], series[0].data[i][1]])
            }

            const datenSkaliert : number[][] = []//erstelle "richtig" skaliertes array mit den daten
            for(let i = 0; i< xyArray.length; i++){
                datenSkaliert.push([scaled(xyArray[i][0], imageWidthLow, imageWidthHigh), scaled(xyArray[i][1], imageHeightLow, imageHeightHigh)])
            }

            let percent: number[][] = calculatePercentages(datenSkaliert) //berechne prozente der punkte auf der linie
            setPercentage(percent)

            const pointNames = ["Start","Point 1", "Point 2", "Point 3", "End"]//erstelle array für json
            const pointNamesAdjusted = ["start_point", "p0", "p1", "p2", "end_point"]
            var sendData : SendData[] = []

            //json  mit gewünschter struktur erstellen und an api senden
            for(let i = 0; i< xyArray.length; i++){
                sendData.push(
                    {
                    id: pointNamesAdjusted[i],
                    x: JSON.stringify(datenSkaliert[i][0]),
                    y: JSON.stringify(datenSkaliert[i][1]),
                    percent: JSON.stringify(percent[i][1]),
                    scaled: JSON.stringify(percent[i][0])
                    }
                )
            }

            var data : any = createLabelArray(xyArray, pointNames, percent)//Labelarray erstellen
            //Setze für jeden Punkt einzeln Labels
            chart?.setOption({
                series: [
                    {
                        id: 'draggablePoints',
                        //type: 'line', //Mit linie
                        smooth: true,
                        type: 'scatter', // ohne linie
                        symbolSize: 10,
                        symbol: 'pin', //
                        itemStyle: {
                            color: '#000',
                            borderColor: 'black'
                        },
                        data: data
                    }]
            });
            setLastSeries(datenSkaliert)
            if(imageBackendID !== undefined) {
               sendDatapointsForImage(username, experimentAndWeldingID[0], imageBackendID, sendData, datenSkaliert)
            }

        }
        return
    }

    /**
     * Bei manueller Auswhl eines Punktes für den Zoom, diesen bzw seine Werte entsprechend an die Linechart komponente übergeben
     * @param value ist jeweils der Punkt welcher ausgeählt wurde
     */
    const handleSelect = (value: string) => {
        if(savedPercentage !== undefined){
            const id : number = Number(value.slice(5))
            setPointForTS(savedPercentage[id][0])
        }
    };

    /**
     * Bei aktivieren des Back-Button wieder die vorherigen aktivieren + vorherige Optionen setzen
     */
    function handleBackControl(){
        showBackButton('none');//nachdem Back geklickt wieder Button ausblenden
        handleBack('Back'); //nach Restart  wird //TODO: passenderen funktionsnamen wie z.b. handleBack oderso
    }

    /**
     *Erzeugung von Punkten+Linie zwischen Start und End (+ Back hier auch berücksichtigen)
     * Ablauf: Lineare Funktion erstellen, punkte gleichmäßig generieren, unterscheidung zwischen length=2 oder 5 um auch "BACK" zu berücksichtigen, sodass einfach nur nmochmal anstatt den Labels eine Linie erzeugt wird
     */
    function handleCreate(){ //Wenn create gedrückt, dann zugehörigen Zustand ändern damit Effect ausgeführt wird und die Start/End Box löschen
        setCreate('create');
        setDeleteBox('delete');
    }

    useEffect(()=> {
        if (chartRef.current !== null && createLine === 'create' ) {
            const chart = getInstanceByDom(chartRef.current);
            const series: any = option?.series;
            const  lineCreationProps ={chart, series, graphicManipulation, windowSize,}
            lineCreation(lineCreationProps)
        }
        handleBack('initial');//Damit erneut zurrükcckgesetzt werden kann
        setRefresh('initial')
    }, [createLine, option, refresh, back])

    function handleRefresh(){ setRefresh('refresh')}//Löst "stucking point" problem

    /**
     * Kann an jeder Stelle aufgerufen werden wenn länge = 5 ist, an welcher Probleme mit dem Pointer auftreten
     */

    const refreshFunction =  () => {
        if (chartRef.current !== null && createLine === 'create') {
            const chart = getInstanceByDom(chartRef.current);
            const series: any = option?.series;

            //Erstellung eines neuen daten Arays mit allen Werten
            var dataNew : number[][] = series[0].data
            const pointSizeFactor : number = 170 //Bestimmt, wie groß die generierten Punkte sind,
            chart?.setOption({
                series: [
                    {
                        id: 'draggablePoints',
                        type: 'line',
                        itemStyle: {
                            borderWidth: 0,
                            borderType: 'solid',
                            color: '#d50303',
                            borderColor: 'red'
                        },
                        lineStyle: {
                            width: 1,
                            color: "#000"
                        },
                        symbolSize: (windowSize.innerWidth+windowSize.innerHeight)/pointSizeFactor, //generierte Punkte abhängig von Fenstergröße
                        data: dataNew,
                    }
                ],
                graphic: graphicManipulation(dataNew, chart)
            });
        }
    }

    /**
     * Box muss dauerhaft entfernt werden
     * Bemerkung: Ohne diesen Abschnitt erscheint die Box immer wieder neu, obwohl sie bereits in der onPointDragging funktion entfernt wurde //TODO: Grund finden und beheben
     */
    useEffect(() => {
        if (chartRef.current !== null && box === 'delete') {
            const chart = getInstanceByDom(chartRef.current);
            chart?.setOption({
                series: removeStartingBox()
            });}
    }, [box, createLine])

    /**
     * In Zustand die Dimnsionen des Koordinatensystems immer aktuell halten wenn sich das fenster oder das zoomlevel ändern
     */
    useEffect(()=>{
        if (chartRef.current !== null) {
            const chart: any = getInstanceByDom(chartRef.current)
            const result : number[]  = getCoordDimension(chart)
            setCoordSystem(result)
        }
    }, [browserZoomLevel, windowSize.innerHeight, windowSize.innerWidth])


    /**
     * Aktuelle Weite und Höhe des Koordinatensystems abrufen
     */
    function getCoordWidthxHeight(){
        if (chartRef.current !== null) {
            const chart: any = getInstanceByDom(chartRef.current)
            const result : number[] = getCoordDimension(chart)
            return result
        }else{
            return [0,0]
        }
    }
    return (
                    <div>
                        <div>
                            <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}
                                 style={{pointerEvents: "auto",  height: ImageYSize, width: "auto"}}>
                                <div className='image-div'   style={{ height: ImageYSize , marginLeft: windowSize.innerWidth*0.09 ,width: windowSize.innerWidth*0.9*0.8, backgroundImage: `url(${imageURLs})`}}></div>
                                <div className="magnify-glass"
                                    style={{backgroundImage: `url(${imageURLs})`, backgroundPosition: `${magnifyPosition.x}% ${magnifyPosition.y}%`, visibility: showMagnify ? "visible" : "hidden", pointerEvents:"auto"}}
                                ></div>
                                      <div>
                                        </div>
                                            <div ref={chartRef}  onMouseMove={mouseObserverInChart}  className="chart-div" style={{ minHeight: "calc(45vh + 20vw)", position: "relative",  marginTop: -ImageYSize-60, width: "90%", height: ImageYSize*2, ...style}}></div>
                                            <TSMapper experimentAndWeldingID={experimentAndWeldingID} pointPercentage={pointForTS} dynamicPoint={dynamicPoint} height = {windowSize.innerHeight-ImageYSize*2} topMargin={topMargin} animation={animation}></TSMapper>
                                       </div>
                                        <div className= "chart-line-cap-div">
                                            <ShowDifferentButtons createLine={createLine} showBack={showBack} forceRestart={forceRestart} getData={getDataAndSetLabels} handleBackControl={handleBackControl} handleCreate={handleCreate} handleRefresh={handleRefresh} initiateCreate={initiateCreate} windowSize={windowSize} handleSelect={handleSelect} handleDelete={handleDelete} fixMagnifingGlass={fixingMagnifingGlass}/>
                                        </div>
                                    </div>
                                </div>
    );
}

export {ImageMapper};
