import { useState, useEffect} from 'react';
import { Layout, Menu } from "antd";
import {getFolderContent, recList} from "../API/api";
import {PictureOutlined} from "@ant-design/icons";
import {getItem, MenuItem} from "./sidebar";


const { Sider } = Layout;

type ImageSideMenuProps = {
    setImageBackendID: (id: number) => void,
    imageBackendID: number,
    setExperimentAndWeldingID: (item: [number, number]) => void,
    experimentAndWeldingID : number[],
};


export function ImageSideMenu({setImageBackendID, imageBackendID, setExperimentAndWeldingID, experimentAndWeldingID} : ImageSideMenuProps): JSX.Element {
    const [collapsed, setCollapsed] = useState(true);
    const [itemsToShow, setItemsToShow] = useState<MenuItem[]>()
    const [folderNames, setFolderNames] = useState<string[][]>([])
    const [sortedNames, setSortedNames] = useState<string[][]>([])



    const savedLastPicture = window.localStorage.getItem("lastpicture");
    const  savedLastWelding = window.localStorage.getItem("lastwelding");
    const savedLastExperiment = window.localStorage.getItem("lastexperiment")

    useEffect(() => {//Im lokalen speicher merken was zuletzt ausgewählt wurde
        // Get the saved username from localStorage, if it exists
        if(savedLastPicture !== null && savedLastWelding !== null && savedLastExperiment !== null) {
            setImageBackendID(parseInt(savedLastPicture));
            setExperimentAndWeldingID([parseInt(savedLastExperiment),parseInt(savedLastWelding)])
        }
   }, []);


    function handleChange(e: any) {
        var id: number = e.key;
        const exp_id = (e.keyPath[1] % 1000)//EXP ID is the fourth digit on 100X
        var selectedElement: string = sortedNames[exp_id-1][id-(exp_id-1)*100]
        for(let i = 0; i<folderNames[exp_id-1].length; i++) {
            if (folderNames[exp_id - 1][i] === selectedElement) {
                setImageBackendID(i)
                let array: string[] = folderNames[exp_id - 1]
                if (array[i].slice(1, 2) === "_") { //Fall für XX_string_XX.png
                    var idForTS: string = array[i].slice(0, 1);
                } else if(!isFirstCharacterNumber(array[i])){//fall für Form "Bild1.png"
                    let str: string = removeLeadingNonDigits(array[i])
                    var idForTS: string = str.match(/^\d+/)![0]
                }
                else {//Fall direkt direkt Nummer verfügbar
                    var idForTS: string = array[i].slice(0, 2);
                }


                var realId: number = Number(idForTS);
                setExperimentAndWeldingID([exp_id, realId])
                break;
            }
        }
    }

    /**
     * Einmalig zum Start der Anwendung die Namen der Bilder aus dem Ordner Laden, in das ItemArray setzen sowie im Zustand folderNames speichern
     */
    useEffect(() => {
        const createMenuItemArray = (arrays: string[][], count: number) => {
            const items: MenuItem[] = [];
            for (let i = 0; i < count; i++) {
                const subItems: MenuItem[] = [];
                for (let j = 0; j < arrays[i].length; j++) {
                    subItems.push(getItem(`${arrays[i][j]}`, `${j + i * 100}`));
                }
                items.push(getItem('Files', `100${i + 1}`, (<><span>{i + 1} </span><PictureOutlined/></>), subItems));
            }
            setItemsToShow(items);
        }


        getFolderContent().then((data: recList) => {
           // Parsen des JSON-Strings in ein JavaScript-Array
            var allExpData = []
            var unsortedFolderNames : string[][] = []
            for(let i = 0; i<data.length; i++){
                var expData :string[] = data[i].values;
                unsortedFolderNames.push(expData)
                const copyToSort : string[] = expData.slice()
                allExpData.push(sort(copyToSort))
            }
            setSortedNames(allExpData)
            setFolderNames(unsortedFolderNames)
            createMenuItemArray(allExpData, data.length)
        }).catch(e => console.log(e))
    },[])


    function containsOnlyNumbers(inputString: string) {
        return /^\d+$/.test(inputString);
    }

    function sort(arr : string[]) {
        if (containsOnlyNumbers(arr[0]) === true) { // Überprüfe den ersten Eintrag im Array
            return arr;
        } else {
            return sortStringsByNumber(arr)
        }
    }
    function endsWithNumber(str: string): boolean {
        return /\d$/.test(str);
    }

    function isFirstCharacterNumber(str: string): boolean {
        const firstChar = str.charAt(0);
        return !isNaN(parseInt(firstChar));
    }

    function removeLeadingNonDigits(str: string): string {
        const match = str.match(/^\D*/);
        if (match) {
            const leadingNonDigits = match[0];
            return str.slice(leadingNonDigits.length);
        }
        console.log("Removed character", str)
        return str;
    }

    function sortStringsByNumber(arr : string[]) {
        arr.sort((a, b) => {
            if(!isFirstCharacterNumber(a) && a !== undefined && b  !== undefined){//For the case "Bild1.png"
                let a_new: string = removeLeadingNonDigits(a)
                let b_new : string = removeLeadingNonDigits(b)
                // @its-ignore
                if( a_new !== null && a_new !== undefined && a_new !== "") {
                    const aNum = parseInt(a_new.match(/^\d+/)![0]);//Das "!" ist Assertionsoperator
                    // @ts-ignore
                    const bNum = parseInt(b_new.match(/^\d+/)![0]);
                    return aNum - bNum;
                }
            }else {
                // @ts-ignore
                const aNum = parseInt(a.match(/^\d+/)[0]);
                // @ts-ignore
                const bNum = parseInt(b.match(/^\d+/)[0]);
                return aNum - bNum;
            }
            return 0
        });
        return arr;

    }

    return (
        <Sider  collapsible={false} collapsed={collapsed}  onCollapse={(value) => setCollapsed(value)}  >
            <Menu theme="dark" defaultSelectedKeys={["0"]} mode="inline"  items={itemsToShow}
                style={{overflow: "auto"}} onClick={handleChange}>
            </Menu>
        </Sider>
    );
}