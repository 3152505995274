import React, {useEffect, useState} from "react";
import { Layout, Menu, MenuProps } from "antd";
import { Outlet, Link, useLocation} from "react-router-dom";
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';

const { Header} = Layout;

function capitalizeFirstLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1)}

export const Home: React.FC = () =>{

    const location = useLocation(); // get the currently selected path
    const selectedKey = location.pathname.substring(1);

    const topMenuItems: MenuProps["items"] = ["timeseries", "weldingmapper", "embedding"].map(
        (key) => ({
            key,
            label: `${capitalizeFirstLetter(key)}`,
        })
    );

    const [isMobile, setIsMobile] = useState(false);
    const [notificationApi, contextHolder] =  notification.useNotification();


    useEffect(() => {
        function handleResize() {
          const isMobileDevice = window.innerWidth <= 800; // You can adjust this threshold as needed
          setIsMobile(isMobileDevice);
        }
    
        // Initial check
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  
  
      const warning = (placement: NotificationPlacement) => {
        notificationApi.error({
          message: 'Larger screen required',
          description: 'This website is best viewed on a larger screen. Please use a larger screen to view this website.',
          placement,
          duration: 0,
        });
      };

    const handleLogoButtonClick = (): void => {
        window.location.href = "/";
    };

    useEffect(() => {
        console.log("Mobile device:", isMobile);

        const asyncWarning = async () => {
          if (isMobile) { 
                  await new Promise(r => setTimeout(r, 1000));
                  console.log("Warning Mobile device!");
                  warning('topLeft');
          }
      }
      asyncWarning();
    }, [isMobile]);

    return(
        <>
            {contextHolder}
            <Layout>
                {contextHolder}
                <Header className="header">
                <div className='logo'>
                    <button onClick={handleLogoButtonClick} style={{ border: 'none', background: 'none' }} aria-label="Home">
                        <img src={`${process.env.PUBLIC_URL}/TMDT_Logo_light_complete.png`} alt="logo" />
                    </button>
                </div>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKey]}>
                        {topMenuItems.map((item) =>{
                            if(item){
                                return(
                                    <Menu.Item key={item.key}>
                                        <Link to={`/${item.key}`}>{capitalizeFirstLetter(item.key as string)}</Link>
                                    </Menu.Item>
                            )}
                        })}
                    </Menu>
                </Header>
                <Outlet />
            </Layout>
        </>
      
    )
}