
import React, { useState, useEffect } from 'react';
import { BgColorsOutlined, EditOutlined, HighlightFilled } from '@ant-design/icons';
import { Avatar, List, Collapse, Space, Modal, Input, message } from 'antd';
import { getLabelItems, LabelItems, LabelItem, updateLabelItems } from "../api";
import ColorPicker from './color-picker';
import { set } from 'zod';


const { Panel } = Collapse;



type propsLabelCollapse = {
    labelExperimentId: number;
    labelColor: LabelItems;
    setLabelColor: Function;
    messageApi: any;
}

function LabelCollapse({labelExperimentId, labelColor, setLabelColor, messageApi}: propsLabelCollapse): JSX.Element {

    const [headerItem, setHeaderItem] = useState<any>([])
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false)
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newColor, setNewColor] = useState<string>("#fd7f6f");
    const [newLabelName, setNewLabelName] = useState<string>("");
    const [newLabelId, setNewLabelId] = useState<number>(-1);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {

        const errorMsg = (msg: string) => {
            messageApi.open({
              type: 'error',
              content: msg,
            });
        };

        if (error) {
            errorMsg(error);
            setError(null);
        }
      }, [error]);
    

    useEffect(() => {
        if (labelExperimentId !== -1) {
            getLabelItems(labelExperimentId).then((labelItems: any | LabelItems) => {
                console.log(labelItems);
                setLabelColor(labelItems);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [labelExperimentId]);

    const genExtra = () => (
        <BgColorsOutlined
            onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            // event.stopPropagation();
            console.log('extra trigger');
            }}
        />
    );

    useEffect(() => {
        // console.log("labelColor", labelColor, panelIsOpen);
        if (labelColor.length > 0 && !panelIsOpen) {
            let list = [];
            for (let i = 0; i < labelColor.length; i++) {
                list.push(<Avatar key={i} style={{ backgroundColor: labelColor[i].color, verticalAlign: 'middle' }} size="small">{labelColor[i].value}</Avatar>)
            }
            let listWithSpace = <Space size={"middle"}>{list}</Space>
            setHeaderItem(listWithSpace);
        } else if (labelColor.length > 0 && panelIsOpen) {
            setHeaderItem("");
        } else {
            setHeaderItem("No labels");
        }

    }, [labelColor, panelIsOpen]);

    const updateLabelItemList = (id: number, color: string, name: string) => {
        let newLabelColor = [...labelColor];
        for (let i = 0; i < newLabelColor.length; i++) {
            if (newLabelColor[i].id === id) {
                newLabelColor[i].color = color;
                newLabelColor[i].name = name;
            }
        }
        setLabelColor(newLabelColor);
    }


    const showModal = (id: number, color: string, name: string) => {
        setNewLabelId(id);
        setNewColor(color);
        setNewLabelName(name);
        setOpen(true);
      };
    
    const handleOk = () => {
        setConfirmLoading(true);
        updateLabelItems(newLabelId, {color: newColor, name: newLabelName}).then((labelItems: any | LabelItems) => {
            console.log(labelItems);
            setLabelColor(labelItems);
            updateLabelItemList(newLabelId, newColor, newLabelName);
            setConfirmLoading(false);
            setOpen(false);
        }).catch((error) => {
            console.log(error);
            setError("Error updating label");
            setConfirmLoading(false);
            setOpen(false);
        });
      };
    
    const handleCancel = () => {
        setConfirmLoading(false);
        setOpen(false);
    };

    return (
        <div>
            <Modal
                title="Change Label"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width="800px"
            >   
                <div>
                    <Input placeholder="Label Name" value={newLabelName} onChange={(e)=>{setNewLabelName(e.target.value)}} />
                    <ColorPicker selectedColor={newColor} setSelectedColor={setNewColor}></ColorPicker>
                </div>
            </Modal>
            <Collapse
                defaultActiveKey={[0]}
                expandIconPosition={'end'}
                onChange={()=>{setPanelIsOpen(!panelIsOpen)}}
            >
                <Panel header={headerItem} key={1}>
                    <List
                        itemLayout="horizontal"
                        dataSource={labelColor}
                        renderItem={(item, index) => (
                            <List.Item actions={[<EditOutlined onClick={()=>{showModal(item.id, item.color, item.name)}} />]}>
                            <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: item.color, verticalAlign: 'middle' }} size="small">{item.value}</Avatar>}
                                title={`${item.name}`}                            />
                            </List.Item>
                        )}
                    />
                </Panel>
            </Collapse>
        </div>
    );
}

export default LabelCollapse;