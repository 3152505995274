import React from 'react';
import {useEffect} from "react";
import './home.css';
import './index.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom"
import {Home} from "./home";
import LandingPage from "./landing-page";
import Impressum from './impressum';
import ErrorPage from "./error-page";
import {Weldingmapper} from "./components/weldingmapper";
import {Timeseries} from "./components/timeseries";
import EmbeddingView from "./components/EmbeddingView/embedding-view";

import {useState} from "react";



function RoutedApp(){
    const [experimentAndWeldingID, setExperimentAndWeldingID] = useState<[number, number]>([-1, -1]); // ehemals [1,2]
    const [imageBackendID, setImageBackendID] = useState<number>(-1); //-1 damit auf keinen Fall ein Bild reingeladen wird wenn bereits eines im Cache ist
    const [alertContent, setAlertContent] = useState<any>(null);

    const  savedLastWelding = window.localStorage.getItem("lastwelding");
    const savedLastExperiment = window.localStorage.getItem("lastexperiment");
    const savedLastPicture = window.localStorage.getItem("lastpicture");

    useEffect(() =>{
    if( savedLastWelding !== null && savedLastExperiment !== null && savedLastPicture !== null) {
        setExperimentAndWeldingID([parseInt(savedLastExperiment),parseInt(savedLastWelding)])
        setImageBackendID(parseInt(savedLastPicture))
    }else{//if application is used for the first time, nothing is inside the cache
        setExperimentAndWeldingID([1,2])
        setImageBackendID(28)
    }

}, []);

    useEffect(()=>{
        localStorage.setItem("lastpicture", imageBackendID.toString())
    }, [imageBackendID])

    useEffect(()=>{
        localStorage.setItem("lastwelding", experimentAndWeldingID[1].toString())
        localStorage.setItem("lastexperiment", experimentAndWeldingID[0].toString())
    }, [experimentAndWeldingID])

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "",
                    element: <LandingPage/>,
                },
                {
                    path: "weldingmapper",
                    element: <Weldingmapper experimentAndWeldingID={experimentAndWeldingID}
                                            setImageBackendID={setImageBackendID} imageBackendID={imageBackendID}
                                            setExperimentAndWeldingID={setExperimentAndWeldingID} alertContent={alertContent}
                                            setAlertContent={setAlertContent} ></Weldingmapper>
                },
                {
                    path: "timeseries",
                    element: <Timeseries selectedWeldingIDldingID={experimentAndWeldingID} setSelectedWeldingID={setExperimentAndWeldingID}></Timeseries>
                },
                {
                    path: "Embedding",
                    element: <EmbeddingView></EmbeddingView>
                },
                {
                    path: "Impressum",
                    element: <Impressum></Impressum>
                }
            ]
        }
    ]);
    return  <RouterProvider router={router}/>
}

function App() {
    return (
        <div>
            <RoutedApp />
        </div>
    );
}

export default App;

