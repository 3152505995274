import {createLineFunction} from "../Calculations/calculations";

interface LineCreationProps{
    chart : any,
    series: any,
    graphicManipulation: (restoredSeries: number[][], chart: any) => void,
    windowSize: any,
}

export function lineCreation(props: LineCreationProps) {
    let steigung: number = (props.series[0].data[1][1] - props.series[0].data[0][1]) / (props.series[0].data[1][0] - props.series[0].data[0][0])
    let bConstant: number = props.series[0].data[0][1] - steigung * props.series[0].data[0][0]
    let save_begin: number[] = [props.series[0].data[0][0], props.series[0].data[0][1]]
    let save_end: number[] = [props.series[0].data[1][0], props.series[0].data[1][1]]


//Erstellung eines neuen daten Arays mit allen Werten
    var dataNew: number[][] = createLineFunction(props.series, save_begin, save_end, steigung, bConstant)

    props.chart?.setOption({//Lediglich für Animation notwnendig, kann funktionell gesehen auch weggelassen werden
        series: [{
            id: 'draggablePoints',
            type: "scatter"
        }]
    });

    const pointSizeFactor: number = 170 //Bestimmt, wie groß die generierten Punkte sind

    props.chart?.setOption({
        series: [
            {
                id: 'draggablePoints',
                type: 'line',
                itemStyle: {
                    borderWidth: 0,
                    borderType: 'solid',
                    color: '#d50303',
                    borderColor: 'red'
                },
                lineStyle: {
                    width: 1,
                    color: "#000"
                },
                symbolSize: (props.windowSize.innerWidth + props.windowSize.innerHeight) / pointSizeFactor, //generierte Punkte abhängig von Fenstergröße
                data: dataNew,
            }
        ],
        graphic: props.graphicManipulation(dataNew, props.chart)
    });
}