import "./scatterchart.css";
import React, { useRef, useEffect, useState, CSSProperties, lazy } from "react";
import { EChartsOption, getInstanceByDom, init, SetOptionOpts } from "echarts";
import type { ECharts } from "echarts";
import { ReactLineChart } from "./line-plot";
import { message as AntMessage } from 'antd';
import { FloatButton, Col, Row, message, Divider, Tooltip } from 'antd';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getScatterLabels, getScatterData, ScatterDataType } from "./api";
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import myColors from "./my-colors";
import { set } from "zod";



type ReactEchartsPropsEmbedding = {
    option: EChartsOption;
    selectEmbd: number;
    selectFeat: number;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    messageApi: typeof AntMessage;
    theme?: "light" | "dark";
}

function ReactScatterChart({ option, selectEmbd, selectFeat, settings, messageApi, theme
}: ReactEchartsPropsEmbedding): JSX.Element {

    const chartRef = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [animationRunning, setAnimationRunning] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState(-1);


    const [playSequenceList, setPlaySequenceList] = useState<number[]>([]);
    const originSequence = [15873, 12511, 15700, 1132, 15896, 14979, 15910, 14479, 721, 14840, 15501, 15620, 
        13179, 7874, 12709, 15511, 15160, 15257, 11635, 14472, 15867, 14769, 15845, 15275, 13338, 15589, 15648, 
        15791, 15565, 16000, 14986, 15761];
    

    const [selectedID, setSelectedID] = useState<number>(1);
    const [mappingSelected, setMappingSelected] = useState<[number, number]>([-1, -1]);

    const [scatterData, setScatterData] = useState<ScatterDataType>({ data: [] });
    const [scatterLabels, setScatterLabels] = useState<any>([])

    type IdxMappingType = { 
            [key: number]: number
    }
    
    const [idxMapZeroState, setIdxMapZeroState] = useState<IdxMappingType>({});
    const [idxMapOneState, setIdxMapOneState] = useState<IdxMappingType>({});
    const [idxMapTwoState, setIdxMapTwoState] = useState<IdxMappingType>({});
    const [idxMapMinusOneState, setIdxMapMinusOneState] = useState<IdxMappingType>({});

    const waitTillReload: number = 30
    const binSize = 25;
    const dataHoverSubject = new BehaviorSubject<any>(null);

    type NamingMap = {
        [key: number]: [string, string];
    }

    const namingMaps: NamingMap[] = [
        {
            0: ["", myColors.none],
            1: ["Good Quality", myColors.colorGreen],
            2: ["Bad Quality", myColors.colorOrange],
            3: ["", myColors.none],
        },
        {
            0: ["No short circuit", myColors.colorBlue],
            1: ["Short short circuit", myColors.colorGreen],
            2: ["Medium short circuit", myColors.colorYellow],
            3: ["Long short circuit", myColors.colorRed],
        }
    ]




    dataHoverSubject?.pipe(
        debounceTime(waitTillReload)
    ).subscribe((idx: any) => {
        if (idx === null || idx.seriesIndex === undefined || idx.dataIndex === undefined) {
            return;
        }
        let seriesIDX = idx.seriesIndex;
        let dataIndex = idx.dataIndex;

        setMappingSelected([seriesIDX, dataIndex]);
    });

    useEffect(() => {
        if (mappingSelected[0] !== -1 && mappingSelected[1] !== -1) {
            let seriesIDX = mappingSelected[0];
            let dataIndex = mappingSelected[1];
            // switch idxMapZeroState
            switch (seriesIDX) {
                case 0:
                    if (idxMapMinusOneState[dataIndex] === undefined) {
                        console.log("Mapping not found: ", seriesIDX, dataIndex, idxMapMinusOneState);
                        return;
                    }
                    setSelectedID(idxMapMinusOneState[dataIndex]);
                    break;
                case 1:
                    if (idxMapZeroState[dataIndex] === undefined) {
                        console.log("Mapping not found: ", seriesIDX, dataIndex, idxMapZeroState);
                        return;
                    }
                    setSelectedID(idxMapZeroState[dataIndex]);
                    break;
                case 2:
                    if (idxMapOneState[dataIndex] === undefined) {
                        console.log("Mapping not found: ", seriesIDX, dataIndex, idxMapOneState);
                        return;
                    }
                    setSelectedID(idxMapOneState[dataIndex]);
                    break;
                case 3:
                    if (idxMapTwoState[dataIndex] === undefined) {
                        console.log("Mapping not found: ", seriesIDX, dataIndex, idxMapTwoState);
                        return;
                    }
                    setSelectedID(idxMapTwoState[dataIndex]);
                    break;
                default:
                    console.log("Mapping not found: ", seriesIDX, dataIndex);
                    return;
            }


        }
    }, [mappingSelected]);


    type minMaxAxis = {
        xAxisMin: number;
        xAxisMax: number;
        yAxisMin: number;
        yAxisMax: number;
    }

    const transformToHistBins = (data: [number, number][], bins: number, dimension: number, minMaxAxis: minMaxAxis) => {
        /**
         * Transform data array to histogram bins for a given dimension
         * @param data: data array
         * @param bins: number of bins
         * @param dimension: dimension to transform
         * @returns: histogram bins 
         */
        if (dimension < 0 || dimension > 1) {
            throw new Error("Invalid dimension. Only 0 (x) and 1 (y) are supported.");
        }

        if (bins <= 0) {
            throw new Error("Number of bins must be greater than 0.");
        }

        // Find the minimum and maximum values for the chosen dimension
        let minVal: number;
        let maxVal: number;

        if (dimension === 0) {
            minVal = minMaxAxis.xAxisMin;
            maxVal = minMaxAxis.xAxisMax;
        } else {
            minVal = minMaxAxis.yAxisMin;
            maxVal = minMaxAxis.yAxisMax;
        }

        // Calculate the bin width
        const binWidth = (maxVal - minVal) / bins;

        // Initialize the histogram bins
        const histBins: number[] = Array(bins).fill(0);

        // Populate the histogram bins based on data distribution
        for (const point of data) {
            const value = point[dimension];
            const binIndex = Math.floor((value - minVal) / binWidth);

            // Ensure the value falls within the last bin if it's equal to maxVal
            const correctedBinIndex = (value === maxVal) ? bins - 1 : binIndex;

            histBins[correctedBinIndex]++;
        }

        return histBins;
    };

    const optionLine: any = {
        animationDuration: 0,
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['current', 'voltage']
        },
        xAxis: [
            {
                type: 'category',
                data: [],
                boundaryGap: false,
                axisPointer: {
                    z: 100
                },
                axisLine: false
            },
        ],
        yAxis: [
            {
                type: 'value',
                position: 'left',
                name: "voltage"
            },
            {
                type: 'value',
                position: 'right',
                name: "current"
            },
        ],
        series: [

            {
                name: 'voltage',
                type: 'line',
                showSymbol: false,
                data: []
            },
            {
                name: 'current',
                type: 'line',
                showSymbol: false,
                data: []
            },
        ]
    };

    const getChartAndCheckOptions = () => {
        if (chartRef.current !== null) {
            const chart: ECharts | undefined = getInstanceByDom(chartRef.current);

            if (chart !== undefined && chart !== null
                && chart.getOption() !== undefined && chart.getOption() !== null) {
                return chart;

            } else {
                console.log("chart is undefined");
                return false;
            }
        } else {
            console.log("chartRef is null");
            return false;
        }
    }

    useEffect(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
            // console.log("init chart");
        }

        const resizeChart = () => {
            chart?.resize();
        }

        window.addEventListener("resize", resizeChart);


        // window.addEventListener("mousemove", mouseMove);

        chart?.on("mouseover", (event) => {
            // console.log("mouse over: " + event.dataIndex);
            dataHoverSubject.next(event);
        });


        chart?.on("click", (event) => {
            // console.log("mouse over: " + event.dataIndex);
            dataHoverSubject.next(event);
        });

        chart?.setOption(option);

        // Download data
        getScatterData(selectEmbd).then((data: ScatterDataType | unknown) => {
            setScatterData(data as { data: [number, number][] });
            getScatterLabels(selectFeat).then((labels: any) => {
                setScatterLabels(labels);
            });
        });

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
            // window.removeEventListener("mousemove", mouseMove);

        };
    }, [option, selectEmbd, selectFeat, settings, theme]);


    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (chart !== undefined) {
                loading ? chart.showLoading() : chart.hideLoading();
            }
        }
    }, [loading]);


    useEffect(() => {
        if (scatterLabels !== undefined && scatterLabels !== null && scatterData !== undefined && scatterData !== null && scatterData.data.length > 0 && scatterLabels.length > 0) {
            let chart = getChartAndCheckOptions();
            if (chart) {
    
                let xValues = scatterData.data.map((value) => value[0]);
                let yValues = scatterData.data.map((value) => value[1]);

                let xMin = Math.min(...xValues);
                let xMax = Math.max(...xValues);

                let yMin = Math.min(...yValues);
                let yMax = Math.max(...yValues);


                let axisInfo: minMaxAxis = {
                    xAxisMin: xMin,
                    xAxisMax: xMax,
                    yAxisMin: yMin,
                    yAxisMax: yMax,
                }


                let classDataZero = scatterData.data.filter((value: any, index: number) => scatterLabels[index] === 0);
                let classDataOne = scatterData.data.filter((value: any, index: number) => scatterLabels[index] === 1);
                let classDataTwo = scatterData.data.filter((value: any, index: number) => scatterLabels[index] === 2);
                let classMinusOne = scatterData.data.filter((value: any, index: number) => scatterLabels[index] === -1);

                // get Index of all data points with scatterLabel 0 from scatterData
                let idxMapZero: {[key: number]: number}  = {}
                let countMapZero = 0;
                let idxMapOne: {[key: number]: number}  = {}
                let countMapOne = 0;
                let idxMapTwo: {[key: number]: number}  = {}
                let countMapTwo = 0;
                let idxMapMinusOne: {[key: number]: number}  = {}
                let countMapMinusOne = 0;

                for (let i  = 0; i < scatterData.data.length; i++) {
                    if (scatterLabels[i] === 0) {
                        idxMapZero[countMapZero] = i;
                        countMapZero++;
                    } else if (scatterLabels[i] === 1) {
                        idxMapOne[countMapOne] = i;
                        countMapOne++;
                    } else if (scatterLabels[i] === 2) {
                        idxMapTwo[countMapTwo] = i;
                        countMapTwo++;
                    } else if (scatterLabels[i] === -1) {
                        idxMapMinusOne[countMapMinusOne] = i;
                        countMapMinusOne++;
                    } else {
                        console.log("unknown label", scatterLabels[i]);
                    }
                }

                // console.log("set IDXMap with selectFeat", selectFeat);
                setIdxMapZeroState(idxMapZero);
                setIdxMapOneState(idxMapOne);
                setIdxMapTwoState(idxMapTwo);
                setIdxMapMinusOneState(idxMapMinusOne);

                let dataList: [number, number][][] = [classMinusOne, classDataZero, classDataOne, classDataTwo];

                let series = []
                // add scatter plot
                for (let i = 0; i < dataList.length; i++) {
                    series.push(
                        {
                            data: dataList[i],
                            name:  (namingMaps[selectFeat][i] !== undefined) ? namingMaps[selectFeat][i][0] : '',
                            itemStyle: {
                                opacity: 0.2,
                                color: namingMaps[selectFeat][i][1],
                            },
                            type: "scatter",
                            symbolSize: 3,
                            animation: false,
                            xAxisIndex: 0,
                            yAxisIndex: 0,
                        }
                    )
                }
                
                // add histogram bins
                for (let dim = 0; dim < 2; dim++) {
                    for (let i = 0; i < dataList.length; i++) {
                        series.push(
                            {
                                data: transformToHistBins(dataList[i], binSize, dim, axisInfo),
                                name:  (namingMaps[selectFeat][i] !== undefined) ? namingMaps[selectFeat][i][0] : '',
                                itemStyle: {
                                    opacity: 1,
                                    color: namingMaps[selectFeat][i][1],
                                },
                                type: "bar",
                                stack: `dim_${dim}`,
                                symbolSize: 0,
                                animation: false,
                                barWidth: '99.3%',
                                xAxisIndex: dim + 1,
                                yAxisIndex: dim + 1,
                                emphasis: {
                                    focus: 'series'
                                },
                            }
                        )
                    }
                }


                let option = {
                    series: series,
                    xAxis: {
                        min: xMin,
                        max: xMax
                    },
                    yAxis: {
                        min: yMin,
                        max: yMax
                    },
                    legend: {
                        selected: namingMaps[selectFeat][0]
                    }
                }
                
                // console.log("set option", option);
                chart.setOption(option);
            }
        }
    }, [scatterLabels, scatterData, selectFeat]);



    const makeDatapointVisible = (dataIndex: number, dataPoint: [number, number]) => {
        let chart = getChartAndCheckOptions();
        if (chart) {
            let options = chart.getOption();
            // Check if series is not undefined or null, typ list and not empty list
            if (options.series !== undefined && options.series !== null
                && Array.isArray(options.series)) {
                let cIndex = dataIndex + 12;
                // console.log("add data point", dataPoint);
                // console.log(cIndex, options.series)
                options.series.push(
                    {
                        type: 'scatter',
                        data: [dataPoint],
                        symbolSize: 8,
                        itemStyle: {
                            color: myColors.highlighted,
                        },

                    }
                );

                chart.setOption(options);

                chart.dispatchAction({
                    type: 'showTip',
                    seriesIndex: cIndex,
                    dataIndex: 0,
                })
            } else {
                console.log("series is empty")
            }
            
        }
    }


    const dropSequencePlot = () => {
        let chart = getChartAndCheckOptions();
        if (chart) {
            
            let tempOptions = chart.getOption() as echarts.EChartsOption;
            // Check if series is not undefined or null, type list and not empty list
            if (
                tempOptions.series !== undefined &&
                tempOptions.series !== null &&
                Array.isArray(tempOptions.series) &&
                tempOptions.series.length > 0
            ) {
                tempOptions = {
                    series: tempOptions.series.slice(0, 12),
                };
                chart.dispatchAction({
                    type: "hideTip",
                });
                chart.setOption(tempOptions, { replaceMerge: ["series"] });
            } else {
                console.log("series is empty");
            }
        }
    };


    const setColorsToBackground = () => {
        let chart = getChartAndCheckOptions();
    
        if (chart) {
            let options = chart.getOption();
            if (options.series !== undefined && options.series !== null
                && Array.isArray(options.series) && options.series.length > 0) {

                    let series = options.series;
                    for (let i = 0; i <= 11; i++) {
                        series[i].itemStyle = {
                            color: myColors.background,
                            opacity: (i <= 3) ? 0.2 : 1,
                        }
                    }
                    // console.log(series);
                    chart.setOption({series: series});
            }
        }
    }

    const resetColors = () => {
        let chart = getChartAndCheckOptions();
        if (chart) {
            let options = chart.getOption();
            let colorList = [];
            for (let i = 0; i <=3 ; i++) {
                colorList.push(namingMaps[selectFeat][i][1]);
            }
            colorList = colorList.concat(colorList, colorList);
            if (options.series !== undefined && options.series !== null
                && Array.isArray(options.series) && options.series.length > 0) {
                    for (let i = 0; i < options.series.length; i++) {
                        if (i <= 11){
                            options.series[i].itemStyle = {
                                color: colorList[i],
                            }
                        }
                        else {
                            options.series[i].itemStyle = {
                                color: myColors.none,
                            }
                        }
                        
                    }
                    chart.setOption(options);
            }
        }
    }


    useEffect(() => {
        if (0 <= currentIndex && currentIndex < playSequenceList.length) {
            let sequenceItem = playSequenceList[currentIndex];
            let dataPoint = scatterData.data[sequenceItem];
            makeDatapointVisible(currentIndex, dataPoint);
            setSelectedID(sequenceItem);
        }
        
    }, [currentIndex]);


    useEffect(() => {
        const waitTime = 1000;

        if (animationRunning) {
          if (currentIndex < playSequenceList.length) {
            const timer = setTimeout(() => {
              setCurrentIndex(currentIndex + 1);
            }, waitTime); // Change this delay as needed
    
            return () => clearTimeout(timer);
          } else {
            setAnimationRunning(false);
            resetColors();
            dropSequencePlot();
            setCurrentIndex(-1);
          }
        }
      }, [currentIndex, animationRunning, playSequenceList]);

      const handleAnimation = () => {
        if (!animationRunning) {
            setAnimationRunning(true);
            setColorsToBackground();
            setPlaySequenceList(originSequence);
        } else {
          setAnimationRunning(false);
          setPlaySequenceList([]);
        }
      };


    return (
        <div>

            <Row>
                <Col span={12}>
                    <div ref={chartRef} className="Scatter-chart" ></div>
                    <Tooltip title="Play preselected sequence">
                        <FloatButton 
                            className="FloatBtnPlay" 
                            onClick={handleAnimation} 
                            shape="circle" 
                            type="primary" 
                            icon={(animationRunning) ? <PauseCircleOutlined /> : <PlayCircleOutlined />} 
                        />
                    </Tooltip>

                </Col>
                <Col span={12}>
                    <ReactLineChart option={optionLine} messageApi={message} dataIdx={selectedID} />
                </Col>
            </Row>

        </div>
    );
}

export type { ReactEchartsPropsEmbedding };
export { ReactScatterChart };
