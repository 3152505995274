/**
 * scaling any number into 0-100 range
 * @param number ist Zahl die neu skaliert wird
 * @param start kleinster Wert im Intervall
 * @param end größter Wert im Intervall
 */
export function scaled(number: number, start : number, end: number) {
    return ((number - start) / (end - start))*100
}

/**
 * Take values from an array with range 0-100 and convertes them to the coordinateSystem
 * @param values Array from 0-100
 * @param minValue the minimum value of the range (e.g. the lower Width border of the Image in the coordinate systems
 * @param maxRange ..
 */
export function scaleBackToCoordinates(value: number, minValue: number, maxValue: number){
         return (((value - 0) / (100 - 0)) * (maxValue - minValue) + minValue);
}

/**
 * Exakte Position des Bildes innerhalb des Koordinatensystems des Charts berechnen
 * @param coordSysWidthxHeight Weite und Höhe des Koordinatensystems
 * @param imageHeight Höhe des Bildes auf dem Display (NICHT die tatsächliche aus dem Backend)
 * @param imageWidth Weite des Bildes auf dem Display
 * @param ImageYSize Höhe des Containers des Bildes
 * @param windowSize Objekt welches die weite und höhe des bildschirmes enthält
 */
export function imageDimensions(coordSysWidthxHeight : number[], imageHeight : number, imageWidth : number, ImageYSize: number, windowSize: any){
        //console.log("Coord to Container ratio",coordSysWidthxHeight[0]/windowSize.innerWidth )
        let pixelOnCoordinateHeight = coordSysWidthxHeight[1]/100 //Wie viele Pixel pro Koordinate Pixel/1
        let currentImageHeight= imageHeight
        let ImageHeightInitial = ImageYSize/(pixelOnCoordinateHeight*2)
        const imageHeightHigh = 100-ImageHeightInitial+(currentImageHeight/(pixelOnCoordinateHeight*2))
        const imageHeightLow = 100-ImageHeightInitial- (currentImageHeight/(pixelOnCoordinateHeight*2))

        let imageWidthLow : number = 0
        let imageWidthHigh: number = 0
        // Position in x-Richtung
        let pixelOnCoordinateWidth = coordSysWidthxHeight[0]/100
        let currentImageWidth= imageWidth

            let sideMargin = ((coordSysWidthxHeight[0])-currentImageWidth)/2
            imageWidthLow = sideMargin/pixelOnCoordinateWidth
            imageWidthHigh = 100-(sideMargin/pixelOnCoordinateWidth)


    return [imageWidthLow, imageWidthHigh, imageHeightLow, imageHeightHigh]
}

/**
 *Nimmt ein Array aus werten und berechnet in auf welchem Prozentstz ein jeweilier Punkt liegt wenn das erste Element im Array 0% und das letzte 100% entspricht
 */
export function calculatePercentages(xyArray : number[][]){
    var percent : number [][] = []
    //berechne laenge der linie
    let laenge =  Math.sqrt(Math.pow(xyArray[4][0]-xyArray[0][0],2)+Math.pow(xyArray[0][1]-xyArray[4][1],2));

    for(let i = 0; i< xyArray.length; i++){
        percent.push([Math.sqrt(Math.pow(xyArray[i][0]-xyArray[0][0], 2) + Math.pow(xyArray[0][1]-xyArray[i][1], 2)) / laenge, Math.sqrt(Math.pow(xyArray[i][0]-xyArray[0][0], 2) + Math.pow(xyArray[0][1]-xyArray[i][1], 2)) * 100 / laenge])
    }
    return percent
}

export function getCoordDimension(chart: any){
    const width: number = chart._coordSysMgr._coordinateSystems[0]._rect.width
    const height: number =  chart._coordSysMgr._coordinateSystems[0]._rect.height
    const echartWidthHeight : number[] = [width, height]
    return echartWidthHeight
}

export function findDifferentElement(arr1: number[][], arr2:number[][]) {
            for (let i = 0; i < arr1.length; i++) {
                if (wholeFunctionMoved(arr1, arr2) && arr1[4][0] !== arr2[4][0]) {//Wenn gesamte funktion sich aufgrund der bewegung des endpunktes bewegt, kann direkt 4 genommen werden
                    return 4
                } else if (arr1[i][0] !== arr2[i][0]) {
                    return i;
                }

        }
    return -1
}

function wholeFunctionMoved(arr1: number[][], arr2: number[][]) {
    let count = 0;
        for (let i = 0; i < arr1[i].length; i++) {
            if (arr1[i][0] !== arr2[i][0] || arr1[i][1] !== arr2[i][1]) {
                count++
            }
    }
    if(count >= 2) {
            return true;
    }
    return false;
}

/**
 * Assuring  that the user cant switch the IDs of the points
 * @param linearDragging
 * @param draggedPointID
 */
export function preventSwitchingIDs(linearDragging: number[][], draggedPointID: number, steigung:  number, bConstant: number){
    const intervalBorder :number  =  1 //Kann ein beliebieger Wert größer Null für eingesetzt werden, dient dazu den intervall in welchem ein Punkt verschoben werden kann leicht zu verkleinern, damit Punkte nicht ineinader verschoben werden können
    const setBackValue :number = 0.5 //Wichtig: Punkt muss zumindest leicht zurrückgesetzt werden, damit die if bedingung nicht mehr greift und Punkt wieder beweglich wird
    if(draggedPointID===0){//Startpunkt wird bewegt
        let newValue :  number
        if(linearDragging[0][0] < linearDragging[4][0]){//ist der startpunkt links?
            if(linearDragging[0][0]<linearDragging[1][0]-intervalBorder){
                return linearDragging
            }else {
                newValue = linearDragging[1][0] - (intervalBorder+setBackValue)
            }
        }else{
            if(linearDragging[0][0]>linearDragging[1][0]+intervalBorder){
                return linearDragging
            }else {
                newValue = linearDragging[1][0] + (intervalBorder+setBackValue)
            }
        }
        linearDragging.splice(draggedPointID, 1, [newValue, steigung * newValue + bConstant])
        return linearDragging
    }else if(draggedPointID===4){
        let newValue : number
        if(linearDragging[4][0]< linearDragging[0][0]){// ist endpunkt links?
            if(linearDragging[4][0]< (linearDragging[3][0]-intervalBorder)){
                return linearDragging
            }else {
                newValue = linearDragging[3][0] - (intervalBorder+setBackValue)
            }
        }else{
            if(linearDragging[4][0]>(linearDragging[3][0]+intervalBorder)){
                return linearDragging
            }else {
                newValue = linearDragging[3][0] + (intervalBorder+setBackValue)
            }
        }
        linearDragging.splice(draggedPointID, 1, [newValue, steigung * newValue + bConstant])
        return linearDragging
    }else if(((linearDragging[draggedPointID+1][0]-intervalBorder > linearDragging[draggedPointID][0] && linearDragging[draggedPointID][0] > linearDragging[draggedPointID-1][0]+intervalBorder) || (linearDragging[draggedPointID+1][0]+intervalBorder < linearDragging[draggedPointID][0] && linearDragging[draggedPointID][0] < linearDragging[draggedPointID-1][0]-intervalBorder))) {
        return linearDragging
    }else{
        const higherIDdifference: number = Math.abs(linearDragging[draggedPointID][0]-linearDragging[draggedPointID+1][0])
        const lowerIDdifference : number = Math.abs(linearDragging[draggedPointID][0]-linearDragging[draggedPointID-1][0])
        const direction : number  = linearDragging[draggedPointID+1][0]-linearDragging[draggedPointID-1][0]
        let newValue : number
        if(direction > 0){//Wert bei größerer ID ist höher: linksrichtung (start links, end rechts)
            if(higherIDdifference > lowerIDdifference){
                 newValue = linearDragging[draggedPointID-1][0]+(intervalBorder+setBackValue)
            }else{
                 newValue = linearDragging[draggedPointID+1][0]-(intervalBorder+setBackValue)
            }
        }else{//rechtsrichtung (start rechts, end linke)
            if(higherIDdifference > lowerIDdifference){
                newValue= linearDragging[draggedPointID-1][0]-(intervalBorder+setBackValue)
            }else{
                newValue= linearDragging[draggedPointID+1][0]+(intervalBorder+setBackValue)
            }
        }
        linearDragging.splice(draggedPointID, 1, [newValue, steigung * newValue + bConstant])
        return linearDragging
    }
}

export function createLineFunction(series : any, save_begin : number[], save_end: number[], steigung: number, bConstant: number){
    if(series[0].data.length === 2) {//TODO: vllt nochmal gucken ob kleiner geht (sehr low priority)
        //Unterscheidung dazwischen ob Anfang links oder rechts gesetzt ist um Punkte richtig hinzuzufügen, länge berücksichtigen damit auch bei kleineren Bildauschnitten funktionsfähig
        if (series[0].data[0][0] < series[0].data[1][0]) {
            const laenge = series[0].data[1][0] - series[0].data[0][0]
            series[0].data.splice(0, 1, save_begin);
            series[0].data.splice(1, 1, [(series[0].data[0][0] + laenge * 1 / 4), steigung * (series[0].data[0][0] + laenge * 1 / 4) + bConstant]);//bei erstem Index enfügen und 0 dabei löschen
            series[0].data.splice(2, 0, [(series[0].data[0][0] + laenge * 2 / 4), steigung * (series[0].data[0][0] + laenge * 2 / 4) + bConstant]);
            series[0].data.splice(3, 0, [(series[0].data[0][0] + laenge * 3 / 4), steigung * (series[0].data[0][0] + laenge * 3 / 4) + bConstant]);
            series[0].data.splice(4, 0, save_end);

        } else {
            const laenge = series[0].data[0][0] - series[0].data[1][0]
            series[0].data.splice(0, 1, save_begin);
            series[0].data.splice(1, 1, [(series[0].data[0][0] - laenge * 1 / 4), steigung * (series[0].data[0][0] - laenge * 1 / 4) + bConstant]);//bei erstem Index enfügen und 0 dabei löschen
            series[0].data.splice(2, 0, [(series[0].data[0][0] - laenge * 2 / 4), steigung * (series[0].data[0][0] - laenge * 2 / 4) + bConstant]);
            series[0].data.splice(3, 0, [(series[0].data[0][0] - laenge * 3 / 4), steigung * (series[0].data[0][0] - laenge * 3 / 4) + bConstant]);
            series[0].data.splice(4, 0, save_end);

        }
    }
    return series[0].data
}