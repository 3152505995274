export interface EchartsSeries {
    name: string;
    type: string;
    data: number [];
    yAxisIndex: number;

}

type TimeSeries = {
    name: string;
    data: [number, number][];
    yAxisIndex: number;
    xAxisIndex: number;
}

type LinePlotData = {
    timeSeries: TimeSeries [];
    timeStamp: TimeStamp;
}

type TimeStamp = {
    data: number [];
    name: string;
}


export type {TimeSeries, TimeStamp, LinePlotData}
// export {TimeSeries, TimeStamp, LinePlotData}

export interface WeldingTimeSeries {
    voltage: EchartsSeries;
    current: EchartsSeries;

}

type Style = {
    color: string;

    opacity: number;
    silent: boolean;
}

type AreaDataName = {
    name: string | undefined;
    xAxis: number;
    itemStyle: Style;
}

type AreaData = {
    xAxis: number;
    itemStyle: Style;
}

export const colorPalette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]


export type MarkedAreaData = [AreaDataName, AreaData];