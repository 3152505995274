import React from 'react';
import { Layout, Typography } from 'antd';
const { Footer } = Layout;
const { Paragraph } = Typography;


const LandingPage: React.FC = () => {
    return (
        <Layout className='layout'>
            <div className='landing-image-div'>
                <img className="landing-image" src={`${process.env.PUBLIC_URL}/landing-page.png`} alt='landing-page' />
            </div>
            <Footer className='footer'>
                <Paragraph>
                    <a href="/impressum">Impressum</a> <br/>
                    Lehrstuhl für Technologien und Management der Digitalen Transformation <br/>
                    Bergische Universität Wuppertal <br/>
                    Rainer-Gruenter-Str. 21 <br/>
                    42119 Wuppertal <br/>
                    Germany
                </Paragraph>
            </Footer>
        </Layout>

    );
};

export default LandingPage;
