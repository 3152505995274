import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FloatButton } from "antd";

export default function PopupGfgforSecondStep(){
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    type WindowSize = {
        innerWidth: number,
        innerHeight: number,

    }

    function getWindowSize() : WindowSize {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    return(
        <div>
            <Popup trigger={<FloatButton
                description="HELP"
                style={{marginBottom: windowSize.innerHeight/2, width: 50}}
                shape="square"

            />}
                   position="left center">
                <div>In this step, you need to <b>adjust the 3 middle points</b> in line on the target. Press <b>save</b> to send the data. If one of the points freezes in, you can press the <b>refresh</b> button to make it movable again!</div>
                <div></div>
                <div></div>
            </Popup>
        </div>
    )
};