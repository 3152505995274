import React, {useRef, useEffect, useState, CSSProperties} from "react";
import { Select, Space} from 'antd';
import { getFeatureList } from "./api";

type SelectorProps = {
    selectedFeat: number
    setSelectedFeat: (value: number) => void
}

type FeatureList = {
    id: number
    name: string
}


export function Selector_Feature({selectedFeat, setSelectedFeat}: SelectorProps) : JSX.Element{


    const [listFeat, setListFeat] = useState<FeatureList[]>([])

    useEffect(() => {
        getFeatureList().then((feature_list: any) => {
            // console.log("feature_list", feature_list);
            setListFeat(feature_list)
        })
    }, []);

    const convertLabel = (name: string) => {
        return <div><em> Color Map:</em> {name}</div>;
    }


    return(
        <div className="FillParent">
            <Space className="FillParent" direction="vertical">
                <Select 
                className="Selector"
                defaultValue= {selectedFeat} 
                onChange={(value: number) => {setSelectedFeat(value)}} 
                options={listFeat.map((feature: FeatureList) => ({ label: convertLabel(feature.name), value: feature.id }))}>
                </Select>
            </Space>
        </div>
    )
}
