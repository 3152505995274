import React from "react";
import "../home.css";
import { Breadcrumb, Layout} from "antd";
import ImageMapperComponent from "./ImageMapper/image-mapper-component";
import { ImageSideMenu } from "./ImageMapper/Sidebar/sideMenu";

const { Header, Content } = Layout;

type WeldingMapperProps = {
    experimentAndWeldingID: number[],
    setImageBackendID: (id: number) => void,
    imageBackendID: number,
    setExperimentAndWeldingID: (item: [number, number]) => void,
    alertContent: any,
    setAlertContent: (item:any) => void,
};


export function Weldingmapper({ experimentAndWeldingID, setImageBackendID, imageBackendID, setExperimentAndWeldingID, alertContent, setAlertContent}: WeldingMapperProps): JSX.Element {

    return(
            <Layout style = {{borderLeft: '1px solid #000'}}>
                <ImageSideMenu setImageBackendID={setImageBackendID} imageBackendID={imageBackendID} setExperimentAndWeldingID={setExperimentAndWeldingID} experimentAndWeldingID={experimentAndWeldingID}/>
                <Layout className="st-layout">
                    <div style={{ position: 'relative' }}>
                        <Breadcrumb  style={{ position: 'absolute', top: 0, left: 0 }} >
                            <Breadcrumb.Item> {alertContent} </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Content className="main-content">
                        <ImageMapperComponent imageBackendID={imageBackendID} experimentAndWeldingID={experimentAndWeldingID} setAlertContentBreadcrumb={setAlertContent} />
                    </Content>
                </Layout>
            </Layout>
    )
}