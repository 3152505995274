function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export class ZoomIndex {
    start: number;
    end: number;

    maxIndex: number;

    constructor(start: number, end: number, maxIndex: number) {
        this.start = start;
        this.end = end;
        this.maxIndex = maxIndex;
    }

    getExtendedZoomIndex(): [number, number] {
        let distance = this.end - this.start;
        let start = Math.max(0, this.start - distance);
        let end = Math.min(this.maxIndex, this.end + distance);
        return [start, end];
    }

    getZoomIndex(): [number, number] {
        return [this.start, this.end];
    }
}

export class ZoomLevel {
    start: number;
    end: number;


    constructor(start: number, end: number) {
        this.start = start;
        this.end = end;
    }

    convertToZoom(timeStamps: number[]) {
        let maxIndex = timeStamps.length -1 ;
        let startIdx = Math.floor(this.start * maxIndex / 100);
        let endIdx = Math.floor(this.end * maxIndex / 100);
        // console.log(startIdx, endIdx);
        let start = timeStamps[startIdx];
        let end = timeStamps[endIdx];
        // console.log(start, end)
        return new ZoomIndex(start, end, timeStamps[maxIndex]);
    }
}

function* range(start: number, end: number) {
    for (let i = start; i <= end; i++) {
        yield i;
    }
}

function rangeArray(start: number, end: number) {
    return Array.from(range(start, end));
}




export { delay, range, rangeArray }