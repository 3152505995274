import {boolean, z} from "zod";

const SERVER_URL = "https://welding-ai.tmdt.uni-wuppertal.de/api"
// const SERVER_URL = (process.env.NODE_ENV == "production") ?  "https://welding-ai.tmdt.uni-wuppertal.de/api" : "http://localhost:8000/api";


const TimeSeriesAPIObj = z.object({
    t: z.array(z.number()),
    voltage:  z.array(z.number()),
    current:  z.array(z.number())
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type TimeSeriesAPIObj = z.infer<typeof TimeSeriesAPIObj>;


const getWeldingTimeSeries = function (weldingId: number, experimentID: number, min: number | undefined, max: number | undefined) {
    const useDocker = process.env.REACT_APP_TEST_USE_DOCKER == "true"
    const NODE_ENV = process.env.REACT_APP_TEST

    return new Promise((resolve, reject) => {
        let url: string;
        if (min === undefined || max === undefined) {
            url = `${SERVER_URL}/welding/${weldingId}?experiment=${experimentID}`;
        } else {
            url = `${SERVER_URL}/welding/${weldingId}?experiment=${experimentID}&min=${min}&max=${max}`;
        }
        fetch(url).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: TimeSeriesAPIObj) => {
            let parsed = TimeSeriesAPIObj.safeParse(data);
            // console.log(parsed);
            if (parsed.success) {
                let savedParsedData = parsed.data;
                resolve({
                    timeSeries: [
                        {
                            name: "voltage",
                            data: savedParsedData.voltage.map((k: number,i: number) => [savedParsedData.t[i], k]),
                            yAxisIndex: 0,
                            xAxisIndex: savedParsedData.t
                        },
                        {
                            name: "current",
                            data: savedParsedData.current.map((k: number,i: number) => [savedParsedData.t[i], k]),
                            yAxisIndex: 1,
                            xAxisIndex: savedParsedData.t
                        }
                    ],
                    timeStamp: {
                        name: "t",
                        data: savedParsedData.t
                    }
                });
            } else {
                reject("Downloaded data has wrong type");
            }

        }).catch((error) => {
            reject(error);
            console.log(error)
            console.log(error.message)
        });
    });
}

const getWeldingRunIds = function (experimentId: number) {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/experiment/${experimentId}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: any) => {
            let parsed = JSON.parse(data);
            // console.log(parsed);
            if (parsed['welding_runs'] !== undefined) {
                resolve(parsed['welding_runs']);
            } else {
                reject("No measurements found");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


const LabelItem = z.object({
    id: z.number(),
    value: z.string(),
    color: z.string(),
    name: z.string(),
});

type LabelItem = z.infer<typeof LabelItem>;

const LabelItems = z.array(LabelItem).default([]);

type LabelItems = z.infer<typeof LabelItems>;

const getLabelItems = function (labelExperimentId: number) {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/labels/${labelExperimentId}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: LabelItems) => {
            let parsed = LabelItems.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("No labels found");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


const LabelItemUpdate = z.object({
    color: z.string(),
    name: z.string()
});
type LabelItemUpdate = z.infer<typeof LabelItemUpdate>;

const updateLabelItems = function (labelId: number, data: LabelItemUpdate) {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/label/${labelId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: LabelItemUpdate) => {
            // console.log("update data", data);
            let parsed = LabelItemUpdate.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("No labels found");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}



const getExperiments = function () {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/experiments`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: any) => {
            let parsed = JSON.parse(data);
            // console.log(parsed);
            if (parsed['experiment'] !== undefined) {
                resolve(parsed['experiment']);
            } else {
                reject("No measurements found");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const Label = z.object({
    start: z.number(),
    end: z.number(),
    label: z.string()
});

const Labels = z.array(Label).default([]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Labels = z.infer<typeof Labels>;

const getLabelData = function (weldingId: number, experiment: number, labelCategory: number) {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/welding_labels/${weldingId}?experiment=${experiment}&label_experiment_id=${labelCategory}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: Labels) => {
            let parsed = Labels.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("No labels found");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// const LabelCategory = z.object({
//     value: z.string()
// });

const LabelCategory = z.object({
    id: z.number(),
    name: z.string()
});

const LabelCategories = z.array(LabelCategory).default([]);

type LabelCategories = z.infer<typeof LabelCategories>
type LabelCategory = z.infer<typeof LabelCategory>

const getLabelCategories = function () {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/welding_label_categories`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: LabelCategories) => {
            let parsed = LabelCategories.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export { getWeldingTimeSeries, getWeldingRunIds, getExperiments, getLabelCategories, getLabelData, getLabelItems, updateLabelItems, TimeSeriesAPIObj}
export type { LabelCategories, LabelCategory, Labels, Label, LabelItems, LabelItem}