import React, {useEffect, useState} from 'react';
import {ReactEchartsProps} from './Chart/react-echarts-props'
import {ImageMapper} from "./Chart/ImageMapper";
import {useEffectOnce} from "./UseEffectOnce/useEffectOnce";
import {Modal, Form, Input, Button, Affix, Checkbox, FloatButton, Tooltip, message, notification} from 'antd';
import {selectImage} from "./API/api";
import {addUserToBackend, checkIfUserExist} from "./API/api";
import {LogoutOutlined, ZoomInOutlined, SaveOutlined, DatabaseOutlined, FileAddOutlined} from '@ant-design/icons';
import { Alert, Space, Breadcrumb,Spin } from 'antd';


type ImageMapperProps = {
    imageBackendID: number,
    experimentAndWeldingID: number[],
    setAlertContentBreadcrumb: (value: any) => void,
}

export function ImageMapperComponent({imageBackendID, experimentAndWeldingID, setAlertContentBreadcrumb}: ImageMapperProps): JSX.Element {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [username, setUsername] = useState('');
    const [checked, setChecked] = useState(false);
    const [showCheckBox, setCheckBox] = useState(false) //Erst bei falscher eingabe einblenden
    const [alertContent, setAlertContent] = useState<any>(null);


    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    /**
     *
     * Functions for Login Control
     */
    const savedUsername = window.localStorage.getItem("username");
    useEffect(() => {
        // Get the saved username from localStorage, if it exists
        if(localStorage.getItem("username")) {
            if(savedUsername !== null) {
                setUsername(savedUsername);
                if(savedUsername === "S1" || savedUsername === "S2"|| savedUsername === "S3" || savedUsername === "S4") {
                    const breadcrumb = `Saving data temporary to ${savedUsername}`

                    const testcrumb = (
                        <Breadcrumb>
                            <Breadcrumb.Item> {savedUsername}</Breadcrumb.Item>
                            <Breadcrumb.Item>EXP {experimentAndWeldingID[0]}</Breadcrumb.Item>
                            <Breadcrumb.Item>WLD {experimentAndWeldingID[1]}</Breadcrumb.Item>
                        </Breadcrumb>
                    );

                    //setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
                    setAlertContentBreadcrumb(testcrumb);
                }else{
                    const breadcrumb = `Saving data to ${savedUsername}`
                    const testcrumb = (
                        <Breadcrumb>
                            <Breadcrumb.Item> {savedUsername}</Breadcrumb.Item>
                            <Breadcrumb.Item>EXP {experimentAndWeldingID[0]}</Breadcrumb.Item>
                            <Breadcrumb.Item>WLD {experimentAndWeldingID[1]}</Breadcrumb.Item>
                        </Breadcrumb>
                    );
                    //setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
                    setAlertContentBreadcrumb(testcrumb)
                }
            }else{
                const breadcrumb = `No saving slot selected!`
                setAlertContentBreadcrumb(showAlert(breadcrumb, 'warning'));
            }
        }
    }, []);

    useEffect(()=>{
        if( savedUsername !== null){
            const testcrumb = (
                <Breadcrumb>
                    <Breadcrumb.Item> {savedUsername}</Breadcrumb.Item>
                    <Breadcrumb.Item>EX {experimentAndWeldingID[0]}</Breadcrumb.Item>
                    <Breadcrumb.Item>WE {experimentAndWeldingID[1]}</Breadcrumb.Item>
                </Breadcrumb>
            );
            //setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
            setAlertContentBreadcrumb(testcrumb)
        }
    }, experimentAndWeldingID)



    useEffectOnce(()=>{
        if(savedUsername === null){
            const breadcrumb = `No saving slot selected!`
            setAlertContentBreadcrumb(showAlert(breadcrumb, 'warning'));
        }
    })
    const showModal = () => {
        if(savedUsername === null || savedUsername==="S1" || savedUsername==="S2"|| savedUsername==="S3"){
        setIsModalVisible(true);}
        else{
            showYouHaveASlotNotification()
        }
    };
    const showYouHaveASlotNotification = () => {
        notification.open({
            message: 'Attention!',
            description: 'You already have an exclusive slot!',
            duration: 3, // Duration in seconds
        });
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function handleCheckboxChange(e : any) {
        setChecked(e.target.checked);
    }
    const handleUsernameChange = (event: any) => {
        setUsername(event.target.value);
    };
    const handleSave = (event: any) => {
        if(username !== null) {
            addUserToBackend(username).then((value: any) => {
                console.log(value)
                if (value !== "Exists") {
                    localStorage.setItem("username", `${username}`)
                    const breadcrumb = `Saving data to ${username}`
                    setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
                    setIsModalVisible(false)
                    newSlotCreated()
                } else if (value === "Exists" && checked === true) {
                    localStorage.setItem("username", `${username}`)
                    const breadcrumb = `Saving data to ${username}`
                    setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
                    setIsModalVisible(false)
                } else {
                    const alert = `Slot "${username}" already exists. Choose another one!`
                    setAlertContent(showAlert(alert, 'error'));
                    setCheckBox(true)

                }
            }).catch(e => console.log(e))
        }
    };
    const newSlotCreated = () => {
        notification.open({
            message: 'Success!',
            description: `Created slot: ${username}`,
            duration: 3, // Duration in seconds
        });
    };

    const showAlert = (message : any, type : any) => {
        return (
            <div style={{padding: "5px"}}>
            <Alert message={message} type={type} />
            </div>
        );
    };

    var symbolSize = (windowSize.innerWidth + windowSize.innerHeight) / 170;

    var data: number[][] = [
        [54, 15],
        [46, 15],
    ];

    const option: ReactEchartsProps["option"] = {
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        xAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        yAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        series: [
            {
                id: 'draggablePoints',
                type: 'line',
                itemStyle: {
                    borderType: 'solid',
                    color: 'black',
                },
                lineStyle: {
                    width: 0
                },
                symbolSize: symbolSize,
                data: data,
                label: {
                    show: true,
                    position: 'top',
                    distance: 1,
                    color: "black",
                    fontSize: (windowSize.innerWidth+windowSize.innerHeight)/250,
                    formatter: function (d) {
                        const darray: any = d.data
                        if (darray[0] === 54) {// Start nur labeln wenn Punkt noch nicht bewegt wurde
                            return "Start";
                        }
                        if (darray[0] === 46) { //gleiches gilt für End
                            return "End";
                        } else {
                            return '';
                        }
                    }
                }
            }, { //Rahmen um Start und Endpunkt legen in Abhängigkeit der initialen Punkte
                id: 'frame1',
                type: 'line',
                symbol: 'none',
                silent: true,
                smooth: true,
                itemStyle: {
                    color: 'black',
                    borderColor: 'black'
                },
                data: [
                    [data[0][0] + 4, data[0][1] - 2],
                    [data[1][0] - 4, data[0][1] - 2]
                ],
            }, {
                id: 'frame2',
                type: 'line',
                symbol: 'none',
                silent: true,
                smooth: true,
                itemStyle: {
                    color: 'black',
                    borderColor: 'black'
                },
                data: [
                    [data[1][0] - 4, data[0][1] + 5],
                    [data[0][0] + 4, data[0][1] + 5]
                ],
            }, {
                id: 'frame3',
                type: 'line',
                symbol: 'none',
                silent: true,
                smooth: true,
                itemStyle: {
                    color: 'black',
                    borderColor: 'black'
                },
                data: [
                    [data[0][0] + 4, data[0][1] - 2],
                    [data[0][0] + 4, data[0][1] + 5]
                ],
            }, {
                id: 'frame4',
                type: 'line',
                symbol: 'none',
                silent: true,
                smooth: true,
                itemStyle: {
                    color: 'black',
                    borderColor: 'black'
                },
                data: [
                    [data[1][0] - 4, data[0][1] - 2],
                    [data[1][0] - 4, data[0][1] + 5]
                ],
            }
        ],
        grid:
            {
                show: false
            },
    };


        const handleSelect = (value: string) => {
            if( savedUsername === null || (savedUsername === "S1" || savedUsername === "S2" || savedUsername === "S3")) {
                const breadcrumb = `Saving data temporary to ${value}`
                setAlertContentBreadcrumb(showAlert(breadcrumb, 'success'));
                localStorage.setItem("username", `${value}`)
                setUsername(value);

                (() => {
                    notification.open({
                        message: 'Success!',
                        description: `Selected temporary slot ${value}!`,
                        duration: 3, // Duration in seconds
                    });
                })();
            }else {
                //cantDownGrade()
                (() => {
                    notification.open({
                        message: 'Attention!',
                        description: "Can't downgrade to temporary if you already have exclusive!",
                        duration: 3, // Duration in seconds
                    });
                })();
            }
        }


    return (
        <div>
            <div>
                <ImageMapper option={option} imageBackendID={imageBackendID} username={username} experimentAndWeldingID={experimentAndWeldingID} allowDataLoad={!isModalVisible} />
                <Affix
                    style={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        zIndex: 9999, // Adjust the z-index as per your requirements
                    }}
                >
                    <FloatButton.Group
                        trigger="hover"
                        type="primary"
                        icon={<SaveOutlined></SaveOutlined>}
                        style={{ marginBottom: "-3vh", marginLeft:"85vw",  left: "10vw"}}
                    >
                        <Tooltip title="Temporal Save 1" placement="left">
                        <FloatButton description="S1"
                                     onClick={()=>handleSelect("S1")} icon={<SaveOutlined></SaveOutlined>}/>

                        </Tooltip>
                        <Tooltip title="Temporal Save 2" placement="left">
                        <FloatButton description="S2"
                                     onClick={()=>handleSelect("S2")} icon={<SaveOutlined></SaveOutlined>}/>
                        </Tooltip>
                        <Tooltip title="Temporal Save 3" placement="left">
                        <FloatButton description="S3"
                                     onClick={()=>handleSelect("S3")} icon={<SaveOutlined></SaveOutlined>}/>
                        </Tooltip>
                        <Tooltip title="Exclusive Save"  placement="left">
                        <FloatButton
                                     onClick={showModal}  icon={<FileAddOutlined />}/>
                    </Tooltip>
                    </FloatButton.Group>
                </Affix>
                <Modal title="Create an exclusive save slot!" open={isModalVisible} onOk={handleSave} onCancel={handleCancel}>
                    {alertContent}
                    <Form>
                        <Form.Item label="Give it a name:">
                            <Input type="text" pattern="[^\s]+" onChange={handleUsernameChange} />
                        </Form.Item>
                        Temporal save slots are accsesible for everyone, this one is exclusive.
                    </Form>
                </Modal>
            </div>
        </div>
    );

}
export default ImageMapperComponent;
