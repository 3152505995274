import * as z from 'zod';


const SERVER_URL = (process.env.NODE_ENV == "production") ?  "https://welding-ai.tmdt.uni-wuppertal.de/api" : "http://localhost:8000/api";

type ReciveData  =  {
    EXP_1: string[],
    EXP_2: string[],
    EXP_3: string[]
}
const ReciveSchema = z.object({
    name:  z.string(),
    values: z.array(z.string())
})

const recList = z.array(ReciveSchema).default([])
export type recList = z.infer<typeof recList>


type SendData={
    id: string,
    x: string,
    y: string,
    percent: string,
    scaled: string
}

const SendDataSchema = z.object({
    id: z.string(),
    x: z.string(),
    y: z.string(),
    percent: z.string(),
    scaled: z.string(),
});



/**
 * Array mit allen verfügbaren Bildern aus Backend abrufen
 */
export function getFolderContent(): Promise<recList> {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/currentFolder`, {method: "GET"})
            .then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((data: ReciveData) => {
                        try {
                            const controlledData =  recList.parse(data);
                            //parse can be used instead of SafeParse if you want to throw an exception instead of handling with if/else
                            resolve(controlledData);
                        } catch (error) {
                            reject(`Invalid response data: ${error}`);
                        }
                    });
                } else {
                    let errorCode = response.status;
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}

/**
 * anhand id Bild in Backend auswählen
 * @param id entspricht der Position des Bildes im array (Array im Backend extra eingespeichert), d.h. NICHT anhand der Welding
 */
export function selectImage(id: number, experiment: number): Promise<string[]>{
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/specificImage/${experiment}/${id}`, {method: "GET"})
            .then((response: Response) => {
                if (response.status === 200) {
                    let url: string = response.url
                    resolve([url])
                } else {
                    let errorCode: number = response.status
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}



export function addUserToBackend(username: string){
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/usernames/${username}`, {method: "POST"})
            .then((response: Response) => {
                if (response.status === 200) {
                    response.json().then(data => {
                        resolve(data.message);
                    }).catch(error => {
                        reject(`Error parsing response JSON: ${error}`);
                    });
                } else {
                    let errorCode: number = response.status
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}


export function checkIfUserExist(username: string): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/usernamecheck/${username}`, {method: "GET"})
            .then((response: Response) => {
                if (response.status === 200) {
                    response.json().then(data => {
                        resolve(data.message);
                    }).catch(error => {
                        reject(`Error parsing response JSON: ${error}`);
                    });
                } else {
                    let errorCode: number = response.status
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}

export function sendDatapointsForImage(username: string, experiment: number, id: number, data: SendData[], rawArray: number[][]){

    try {
        SendDataSchema.array().parse(data);

        const requestData = {
            "structured": data,
            "scaled_p0": data[1].scaled,
            "scaled_p1": data[2].scaled,
            "scaled_p2": data[3].scaled,
            "raw": rawArray
        };

        console.log(requestData)
        fetch(`${SERVER_URL}/mapper/createFile/${username}/${experiment}/${id}`, {//send data as post request to backend
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({data: requestData})
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
            })
    }catch(error) {
        //Data is not valid
        const validationError = error as z.ZodError;
        console.error('Invalid data:', validationError);
        return;
    }
}


export function searchfordata(username: string, experiment: number, id: number){
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/searchfordata/${username}/${experiment}/${id}`, {method: "GET"})
            .then((response: Response) => {
                if (response.status === 200) {
                    response.json().then(data => {
                        resolve(data);
                    }).catch(error => {
                        reject(`Error parsing response JSON: ${error}`);
                    });
                } else {
                    let errorCode: number = response.status
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}

export function deletefile(username: string, experiment: number, id: number){
    return new Promise((resolve, reject) => {
        fetch(`${SERVER_URL}/mapper/deletefile/${username}/${experiment}/${id}`, {method: "GET"})
            .then((response: Response) => {
                if (response.status === 200) {
                    resolve(()=>{})
                } else {
                    let errorCode: number = response.status
                    reject(`${errorCode}: ${response.statusText}`);
                }
            })
    })
}