import React from 'react';
import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';




const ExplanationText: React.FC = () => {


    return (
      <div className = "Tabs"> 
        <Tabs className="Tabs"
          tabPosition={'left'}
          defaultActiveKey="0"
        ><TabPane tab="Introduction" key="0">
           <div>
            <h5>ASIMOW Project</h5>
            <p>
              The ASIMOW Project aims to develop a system for inline quality control of arc welding processes.
              During the welding process, several sensors collect <strong>voltage</strong> and <strong>current</strong> data, which can indicate
              the quality of the finished product. In the image below, you can see an overview of the process.
              <br />
              <br />
            </p>
            <img src="screenshot_process_illustration.png" alt="Process Illustration" className='ExpImageW'/>
          </div>
        </TabPane>
        <TabPane tab="The Data" key="1">
           <div>
            <h5>The Data</h5>
            <p>
              The measured time series data is split into thousands of short characteristic <strong>cycles</strong>,
              as pictured below.
              These cycles on their own do not provide enough information to determine the quality of a weld.
              A cycle consists of a deflection of the current curve and the corresponding reaction 
              of the voltage. This process can be divided into three phases and sometimes a short circuit occurs.
              <br />
              <br />
            </p>
            <img src="cycle_screenshot.png" alt="Cycle Illustration" className='ExpImageW'/>
          </div>
        </TabPane>
        <TabPane tab="What is a VQ-VAE?" key="2">
           <div>
            <h5>What is a VQ-VAE?</h5>
            <p>
              In order to improve our quality classification,
              a <strong>vector-quantised variational autoencoder</strong> (VQ-VAE) is trained on the cycles. The Encoder part of the VQ-VAE
              compresses the cycles into a lower-dimensional latent space, while the Decoder part reconstructs the cycles from the latent space.
              <br />
              <br />
              What makes the VQ-VAE special is the <strong>Vector Quantization step</strong>.
              The already lower dimensions are split into multiple little vectors,
              which are then quantized to a dictionary of discrete vectors, called the codebook.
            </p>
            <img src="vqvae_screenshot.png" alt="Cycle Illustration" className='ExpImageH'/>
          </div>
        </TabPane>
        <TabPane tab="Why use a VQ-VAE?" key="3">
           <div>
            <h5>Why use a VQ-VAE?</h5>
            <p>
              <br />
              There are several reasons why a VQ-VAE is a good choice for this task:
              <ul>
                <li> the compressed data is <strong>easier to compute</strong> with, as it is lower-dimensional. </li>
                <li> we hope to extract only the most relevant features by reducing the dimensionality of the original data</li>
                <li> the discretization in the VQ-VAE and the trained codebook allow us to <strong>interpret the codebook entries</strong> as
                   the features that determine a good or bad quality weld
                </li>
                <li> depending on the encoder, we can also try to extract <strong>positional information</strong> from the time series data</li>
              </ul>

            </p>
          </div>
        </TabPane>
        <TabPane tab="Better Visualization" key="4">
           <div>
            <h5>Better Visualization</h5>
            <p>
              Understanding the quantized latent space is not trivial, as it can still contain multiple dimensions.
              In order to <strong>interpret the latent space</strong> and the codebook entries, a visualization is needed.
              Therefore we use the UMAP algorithm or the TSN-E algorithm to furthermore reduce the latent space to 2 dimensions.
              The resulting 2-dimensional vectors for all cycles are then plotted in a scatter plot.
              <br />
              <br />
              <strong>Feel free to hover over the scatter plot down below and explore the corresponding data
               and understand how different cycles are related!</strong>
              <br />
              <br />
              Depending on the selected feature, 
              the cycles are colored according to their quality or the length of an occuring short circuit.
            </p>
          </div>
        </TabPane>
        </Tabs>
      </div>
    );
  };

  export default ExplanationText;