import PopupGfgforFirstStep from "../Popup/ShowPopup";
import {FloatButton, Tooltip,  Space, Button, notification} from "antd";
import PopupGfgforSecondStep from "../Popup/ShowPopup2";
import {ZoomInOutlined, ReloadOutlined, DeleteOutlined, SaveOutlined, AimOutlined} from '@ant-design/icons';
import React from "react";
import {useState,  useEffect} from "react";

type WindowSize = {
    innerWidth: number,
    innerHeight: number,
}
type ShowDifferentButtonsProps = {
    initiateCreate: string,
    createLine: string,
    forceRestart: string,
    windowSize: WindowSize,
    showBack: string,
    handleCreate: () => void,
    getData: ()=> void,
    handleRefresh: ()=> void,
    handleBackControl: ()=> void,
    handleSelect: (value: string)=> void,
    handleDelete: ()=> void,
    fixMagnifingGlass: (value: number)=>void,

}

/**
 * Individuelle Buttons für jeden Schritt einblenden + im letzten Schritt eine option für das Zoomen zu verfügung stellen
 */
export function ShowDifferentButtons({initiateCreate, createLine, forceRestart, windowSize, showBack, handleCreate, getData, handleRefresh, handleBackControl, handleSelect, handleDelete, fixMagnifingGlass }: ShowDifferentButtonsProps): JSX.Element {
    const [api, contextHolder] = notification.useNotification();
    const [buttonClicked, setButtonClicked] = useState(true);



    const toggleButtonState = () => {
        setButtonClicked(!buttonClicked);
        if(!buttonClicked === true){
        fixMagnifingGlass(1)
        }else{
        fixMagnifingGlass(0)
        }
    };

    const buttonColor = buttonClicked ?  'white': '#1890ff';
    const buttonTooltip = buttonClicked ? 'Fix magnifying glass to line':'Fix magnifying glass to mouse' ;

    const close = () => {
        console.log('Notification closed');
    };

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="link" size="small" onClick={() => api.destroy()}>
                    Cancel
                </Button>
                <Button type="primary" size="small" onClick={() => handleDelete()}>
                    Confirm
                </Button>
            </Space>
        );
        const style = {right: "5vw",}

        api.open({
            message: 'Attention!',
            description:
                'Are you sure you want to restart?',
            btn,
            key,
            style,
            onClose: close,
        });
    };

if(initiateCreate === 'initial'){
    return <div className='button'><br/><br/><PopupGfgforFirstStep></PopupGfgforFirstStep></div>;
}
else if(createLine === 'initial' && initiateCreate === "initiate" && forceRestart === 'initial'){
    return <div className='button' style={{marginTop: "-10vh"}}><FloatButton
        description="CREATE"
        shape="square"
        type="primary"
        style={{ marginBottom: (windowSize.innerHeight/2) +50, width: 50}}
        onClick={handleCreate}
    /><PopupGfgforFirstStep></PopupGfgforFirstStep></div>
}
else if(forceRestart === 'yes'){
    return <div className='button'  style={{marginTop: "10vh"}}><br/><br/><br/><br/><PopupGfgforFirstStep></PopupGfgforFirstStep></div>;
}
else if (createLine === 'create' && showBack !== 'yes'){
    return <div className='button' style={{marginTop: "-10vh"}} >
        <Tooltip title={buttonTooltip} placement="left">
            <Button
                style={{ bottom:  "1vh", left: "20vw", width: "4vw", backgroundColor: buttonColor, position: "absolute" }}
                onClick={toggleButtonState}
                shape="circle"
                icon={<AimOutlined />}
            />
        </Tooltip>
        <FloatButton
            description="SAVE"
            style={{ marginBottom: (windowSize.innerHeight/2)+100, width: 50}}
            shape="square"
            type="primary"
            onClick={getData}
        />
        <Tooltip title="Refresh" placement="left">
        <FloatButton
            icon={<ReloadOutlined/>}
            shape="square"
            style={{ marginBottom: (windowSize.innerHeight/2+50), width: 50 }}
            onClick={handleRefresh}
        />
        </Tooltip>
        <PopupGfgforSecondStep></PopupGfgforSecondStep>
            <>
                {contextHolder}
                <Tooltip title="Clear Data and Restart!" placement="left">
                <FloatButton
                icon={<DeleteOutlined />}
                shape="square"
                style={{ marginBottom: (windowSize.innerHeight/2-50), width: 50 }}
                onClick={openNotification}
                />
                </Tooltip>
            </>
    </div>
}else if(showBack === 'yes'){
    return <div style={{marginTop: "-10vh"}}> <FloatButton
        description="BACK"
        shape="square"
        type="primary"
        style={{ marginBottom: windowSize.innerHeight/2, right: 24, width: 50}}
        onClick={handleBackControl}
    />
        <>
            <FloatButton.Group
                trigger="hover"
                type="primary"
                icon={<ZoomInOutlined/>}
                style={{  bottom: "1vh", left: "20vw", width: "4vw", position:"absolute"}}
            >
                <Tooltip title="Zoom point 1" placement="left">
                <FloatButton description="P1"
                             onClick={()=>handleSelect("point1")}
                /></Tooltip>
                <Tooltip title="Zoom point 2" placement="left">
                <FloatButton description="P2"
                             onClick={()=>handleSelect("point2")}/>
                </Tooltip>
                <Tooltip title="Zoom point 3" placement="left">
                <FloatButton description="P3"
                             onClick={()=>handleSelect("point3")}

                /></Tooltip>
            </FloatButton.Group>
        </>
    </div>
}else{
    return <div style={{marginTop: "-10vh"}}></div>
}
}

