// class with custom colors for the embedding view

type MyColorsType = {
    colorOrange: string,
    colorRed: string,
    colorGreen: string,
    colorBlue: string,
    colorYellow: string,
    background: string,
    highlighted: string
    none: string
}

const myColors: MyColorsType = {
    colorBlue: 'blue',
    colorOrange: 'orange',
    colorGreen: 'green',
    colorRed: 'red',
    colorYellow: 'yellow',
    background: 'rgba(195, 195, 195, 0.35)',
    highlighted: 'rgba(255, 0, 0, 1)',
    none: 'rgba(0, 0, 0, 0)'
}

export type { MyColorsType };
export default myColors;