import {boolean, z} from "zod";
import { TimeSeriesAPIObj } from "../LineChart/api";
 

// const SERVER_URL_LOCAL = "http://localhost:8000"

const SERVER_URL = (process.env.NODE_ENV == "production") ?  "https://welding-ai.tmdt.uni-wuppertal.de/api" : "http://localhost:8000/api";


const ScatterData = z.object({
    data: z.array(z.tuple([z.number(),z.number()])).default([]),
});

const ScatterLabel = z.object({
    label: z.array(z.number()).default([])
});

const EmbeddingItem = z.object({id: z.number(), name: z.string()})

const EmbeddingList = z.array(EmbeddingItem).default([])

const FeatureItem = z.object({id: z.number(), name: z.string()})
const FeatureList = z.array(FeatureItem).default([])

type ScatterDataType = z.infer<typeof ScatterData>
type ScatterLabelType = z.infer<typeof ScatterLabel>
type EmbeddingListType = z.infer<typeof EmbeddingList>
type FeatureListType = z.infer<typeof FeatureList>

const getScatterData = function (id: number = 0) {
    return new Promise((resolve, reject) => {
        let url = `${SERVER_URL}/xai/embedding/${id}`
        fetch(`${url}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: ScatterDataType) => {
            // console.log("get Scatter Data:", data)
            let parsed = ScatterData.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const getScatterLabels = function (id: number = 0) {
    return new Promise((resolve, reject) => {
        let url = `${SERVER_URL}/xai/labels/${id}`
        fetch(`${url}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((label: ScatterLabelType) => {
            // console.log("get label Data:", label)
            let parsed = ScatterLabel.safeParse(label);
            if (parsed.success) {
                return resolve(parsed.data.label);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const getEmbeddingList = function () {
    return new Promise((resolve, reject) => {
        let url = `${SERVER_URL}/xai/embeddings`
        fetch(`${url}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: EmbeddingListType) => {
            // console.log("get EmbeddingList:", data);
            let parsed = EmbeddingList.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const getFeatureList = function () {
    return new Promise((resolve, reject) => {
        let url = `${SERVER_URL}/xai/features`
        fetch(`${url}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: FeatureListType) => {
            // console.log("get FeatureList:", data);
            let parsed = FeatureList.safeParse(data);
            if (parsed.success) {
                return resolve(parsed.data);
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const getLinePlotData = function (cycleID: number) {
    return new Promise((resolve, reject) => {
        let url = `${SERVER_URL}/xai/cycles/${cycleID}`
        fetch(`${url}`).then((response: Response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                let errorCode: number = response.status;
                reject(`${errorCode}: ${response.statusText}`);
            }
        }).then((data: any) => {
            // console.log(data)
            let parsed = TimeSeriesAPIObj.safeParse(data);
            if (parsed.success) {
                let savedParsedData = parsed.data;
                return resolve({
                    timeSeries: [
                        {
                            name: "voltage",
                            data: savedParsedData.voltage.map((k: number,i: number) => [savedParsedData.t[i], k]),
                            yAxisIndex: 0,
                            xAxisIndex: savedParsedData.t
                        },
                        {
                            name: "current",
                            data: savedParsedData.current.map((k: number,i: number) => [savedParsedData.t[i], k]),
                            yAxisIndex: 1,
                            xAxisIndex: savedParsedData.t
                        }
                    ],
                    timeStamp: {
                        name: "t",
                        data: savedParsedData.t
                    }
                });
            } else {
                console.log("Parse Error", parsed.error);
                return reject("Label categories have wrong format.");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


export { getScatterData, getScatterLabels, getEmbeddingList, getFeatureList, getLinePlotData }
export type { ScatterDataType }