import React, {useEffect, useState} from 'react';
import './TSMapperrStyles.css';
import {TsReactEchartsProps} from "./props/ts-react-echarts-props";
import {ReactEchartsLinechart} from "./line_chart";
import { Layout } from 'antd';
import lineChartOptions from "./../../LineChart/line-chart-options";
import {TSMapperProps} from "./TSMapper-props";
import exp from "constants";

const { Content } = Layout;




export function TSMapper({ experimentAndWeldingID, pointPercentage, dynamicPoint, height, topMargin, animation,
                         }: TSMapperProps): JSX.Element {

    // @ts-ignore
    const option: TsReactEchartsProps["option"] = lineChartOptions();

    const [selectedPoint, setSelectedPoint] = useState<number>()

    useEffect(()=>{
        if(pointPercentage !== undefined) {
            setSelectedPoint(pointPercentage)
        }
    },[pointPercentage])


    return (
                <Layout className="st-layout" >
                    <Content className="ts-content">
                        <ReactEchartsLinechart option={option} loading={true} weldingID={experimentAndWeldingID[1]} pointPercentage={selectedPoint} dynamicPoint={dynamicPoint} height={height} topMargin={topMargin} animation={animation} experimentID={experimentAndWeldingID[0]}/>
                    </Content>
                </Layout>
    );
}

