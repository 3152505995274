import "./scatterchart.css";
import React, {useRef, useEffect, useState, CSSProperties} from "react";
import {EChartsOption, getInstanceByDom, init, SetOptionOpts} from "echarts";
import type {ECharts} from "echarts";
import { message as AntMessage } from 'antd';
import { getLinePlotData } from "./api";



type ReactEchartsPropsEmbedding = {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    messageApi: typeof AntMessage;
    theme?: "light" | "dark";
    dataIdx: number;
}



function ReactLineChart({option, settings, messageApi, theme, dataIdx
}: ReactEchartsPropsEmbedding): JSX.Element {

    const chartRef = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState<boolean>(false);

        
    useEffect(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
        }

        const resizeChart = () => {
            //console.log("resize");
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        chart?.setOption(option);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [option, settings, theme]);


    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (chart !== undefined) {
                loading ? chart.showLoading() : chart.hideLoading();
            }
        }
    }, [loading]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            
            getLinePlotData(dataIdx).then((data: any) => {
                if (data !== undefined && data !== null
                    && chart !== undefined && chart !== null) {
      
                    let option = {
                        series: data.timeSeries,
                    };
                    chart.setOption(option);
                }
            });
        }
    }, [dataIdx]);




    return (
        <div>
            <div ref={chartRef} className="Scatter-chart" ></div>
        </div>
    );
}

export {ReactLineChart};
