import React from 'react';
import './impressum.css';

const Impressum: React.FC = () => {
    return (
        <div>
            <div className="tower mt-50 mb-20">
                <h1>Impressum</h1>
                <div className="title">Angaben gemäß §5 TMG</div>

                <div className="text">Lehrstuhl für Technologien und Management der Digitalen Transformation</div>
                <br />
                <div className="text">Bergische Universität Wuppertal</div>
                <br />
                <div className="text">Gaußstraße 20</div>
                <br />
                <div className="text">42119 Wuppertal</div>

                <div className="small_title">Vertreten durch:</div>
                <div className="text">Tobias Meisen</div>

                <div className="title">Kontakt</div>
                <div className="text">E-Mail: <a href="mailto:meisen@uni-wuppertal.de">meisen@uni-wuppertal.de</a></div>

                <div className="title">Haftung für Inhalte</div>
                <div className="text">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</div>


                <div className="title">Haftung für Links</div>
                <div className="text">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.</div>


                <div className="title">Urheberrecht</div>
                <div className="text">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</div>

                <div className="small_title">Bildmaterial:</div>
                <div className="text"> Wir beziehen die Bilder der Webseite zu großen Teilen von www.pexels.com, www.colorbox.com oder aus unserem eigenen Content. Die Bildrechte liegen weiterhin bei den Urhebern.</div>

                <div className="small_title">Quelle:</div>
                <div className="text">e-recht24.de</div>
            </div>
        </div>
    );
};

export default Impressum;
