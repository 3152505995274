import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FloatButton } from "antd";

export default function PopupGfgforFirstStep(){
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    type WindowSize = {
        innerWidth: number,
        innerHeight: number,

    }

    function getWindowSize() : WindowSize {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    return(
        <div>
            <Popup trigger={<FloatButton
                description="HELP"
                shape="square"
                style={{ marginBottom: windowSize.innerHeight/2 ,right: 24, width: 50 }}
            />}
                   position="left center">
                <div>In this initial step, you need to drag the start- and end-point to the start and the end of the tool. <b>Create</b> button will show after that's done!</div>
                <div></div>
                <div></div>
            </Popup>
        </div>
    )
};