import React from 'react';
import { Avatar, Segmented, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';




type propsColorPicker = {
    selectedColor: string;
    setSelectedColor: Function;
}

function ColorPicker({selectedColor, setSelectedColor}: propsColorPicker): JSX.Element {


    const colorPalette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]

    const colorOptions = colorPalette.map((color, index) => {
        return {
            label: (
                <div style={{ padding: 4 }}>
                <Avatar style={{ backgroundColor: color }} />
                <div>{color}</div>
                </div>
            ),
            value: color,
        }
    })

    return (
        <Space direction="vertical">
            <Segmented
            value={selectedColor}
            options={colorOptions}
            onChange={(value) => {setSelectedColor(value)}}
            />
        </Space>
    );
}

export default ColorPicker;