import {removeStartingBox} from "./changeOptions";
import {imageDimensions, scaleBackToCoordinates} from "../Calculations/calculations";

interface RestoreStateProps {
    chart : any,
    setCreate: (value: string) => void;
    setDeleteBox: (value: string) => void;
    setInitiate: (value: string) => void;
    handleBack: (value: string) => void;
    setTopMargin: (value: number) => void;
    option: any;
    savedLastSeries: number[][];
    savedPercentage: number[][];
    imageHeight: number;
    imageWidth: number;
    ImageYSize: number;
    windowSize: any;
    graphicManipulation: (restoredSeries: number[][], chart: any) => void;
    getCoordWidthxHeight: () => number[]
};

/**
 * Falls labels gesetzt sind, die labels wieder setzen auch wenn resized wird, erst bei wechsel des Bildes reset
 */
export function restoreState(props: RestoreStateProps ){
    props.setCreate("create")
    props.setDeleteBox("delete")
    //showBackButton("yes")
    props.setInitiate('initiate')
    props.handleBack('initial');
    props.setTopMargin(-25)


    const series: any = props.option?.series
    props.chart?.setOption({//Box löschen
        series: removeStartingBox()
    });


    let coordSysWidthxHeight : number[] = props.getCoordWidthxHeight()
    let imageDimension : number[] = imageDimensions(coordSysWidthxHeight, props.imageHeight, props.imageWidth, props.ImageYSize, props.windowSize)
    const imageHeightHigh = imageDimension[3]
    const imageHeightLow = imageDimension[2]
    const imageWidthHigh = imageDimension[1]
    const imageWidthLow = imageDimension[0]

    const xyArray : number[][] = []
    for(let i = 0; i< props.savedLastSeries.length; i++){
        xyArray.push([scaleBackToCoordinates(props.savedLastSeries[i][0], imageWidthLow, imageWidthHigh), scaleBackToCoordinates(props.savedLastSeries[i][1], imageHeightLow, imageHeightHigh)])
    }

    series[0].data.splice(0, 1, [xyArray[0][0], xyArray[0][1]]);
    series[0].data.splice(1, 1, [xyArray[1][0], xyArray[1][1]]);
    series[0].data.splice(2, 1, [xyArray[2][0], xyArray[2][1]]);
    series[0].data.splice(3, 1, [xyArray[3][0], xyArray[3][1]]);
    series[0].data.splice(4, 1, [xyArray[4][0], xyArray[4][1]]);

    var  restoredSeries: number[][] =  series[0].data


    var pointSizeFactor : number = 170
    props.chart?.clear()
    props.chart?.setOption({
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        xAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        yAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        series: [
            {
                id: 'draggablePoints',
                type: 'line',
                itemStyle: {
                    borderWidth: 0,
                    borderType: 'solid',
                    color: '#d50303',
                    borderColor: 'red'
                },
                lineStyle: {
                    width: 1,
                    color: "#000"
                },
                symbolSize: (props.windowSize.innerWidth+props.windowSize.innerHeight)/pointSizeFactor, //generierte Punkte abhängig von Fenstergröße
                data: restoredSeries,
            }
        ],
        graphic: props.graphicManipulation(restoredSeries, props.chart)
    });
}