
interface CleanResetProps{
    chart : any,
    series: any,
    graphicManipulation: (restoredSeries: number[][], chart: any) => void,
}

export function cleanReset(props: CleanResetProps) {
    var datapoints: number[][] = [[54, 15], [46, 15]];

    props.series[0].data.splice(4, 1, datapoints[1])
    props.series[0].data.splice(3, 1)
    props.series[0].data.splice(2, 1)
    props.series[0].data.splice(1, 1)
    props.series[0].data.splice(0, 1, datapoints[0])

    var data: number[][] = props.series[0].data
    var realData = data.filter(function (value) {//Muss berechnet werden dan splice empty slots verurusacht (undefined)
        return value !== undefined;
    });
    props.chart?.clear();//cache muss zurrückgesetzt werden
    props.chart?.setOption({
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        xAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        yAxis: {
            min: 0,
            max: 100,
            type: 'value',
            axisLine: {onZero: false},
            show: false
        },
        series: [{
            id: 'draggablePoints',
            type: 'line',
            itemStyle: {
                borderType: 'solid',
                color: 'black',
            },
            lineStyle: {
                width: 0
            },
            data: realData,
        }, {//Rahmen um Start und Endpunkt legen in Abhängigkeit der initialen Punkte
            id: 'frame1',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[0][0] + 4, datapoints[0][1] - 2],
                [datapoints[1][0] - 4, datapoints[0][1] - 2]
            ],
        }, {
            id: 'frame2',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[1][0] - 4, datapoints[0][1] + 5],
                [datapoints[0][0] + 4, datapoints[0][1] + 5]
            ],
        }, {
            id: 'frame3',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[0][0] + 4, datapoints[0][1] - 2],
                [datapoints[0][0] + 4, datapoints[0][1] + 5]
            ],
        }, {
            id: 'frame4',
            type: 'line',
            symbol: 'none',
            silent: true,
            smooth: true,
            itemStyle: {
                color: 'black',
                borderColor: 'black'
            },
            data: [
                [datapoints[1][0] - 4, datapoints[0][1] - 2],
                [datapoints[1][0] - 4, datapoints[0][1] + 5]
            ],
        }],
        graphic: props.graphicManipulation(realData, props.chart)

    });

}