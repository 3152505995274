import React from "react";
import {Select} from "antd";
import {getLabelCategories, LabelCategories, LabelCategory} from "../api"



type MyState = {
    categories: LabelCategories
}

type SelectorProps = {
    callback: Function;
    loading: boolean;
}

class Selector extends React.Component<SelectorProps> {
    state: MyState = {
        categories: [],
    }


    componentDidMount() {
        getLabelCategories().then((label_categories: LabelCategories | unknown) => {
            // console.log("label_categories", label_categories);
            this.setState(() => ({
                categories: label_categories
            }))
        })};

    render() {
        return (

            <Select
                className="label-selector"
                placeholder={"Select Label Category"}
                loading={this.props.loading}
                options={this.state.categories.map((category: LabelCategory) => ({ label: category.name, value: category.id }))}
                onChange={(value: number) => this.props.callback(value)}>
        </Select>
        );
    }
}


export default Selector;