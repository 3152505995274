import "./linechart.css";
import React, {useRef, useEffect, useState, CSSProperties} from "react";
import {EChartsOption, getInstanceByDom, init, SetOptionOpts} from "echarts";
import type {ECharts} from "echarts";
import { LinePlotData, MarkedAreaData } from "./props/time-series";
import { rangeArray, ZoomLevel} from "./utils";
import {getWeldingTimeSeries, getLabelData, Labels, LabelItems} from "./api";
import {BehaviorSubject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Selector from "./menu/selector";
import LabelCollapse from "./menu/label-collapse";
import { Col, Row } from 'antd';
import { message as AntMessage } from 'antd';



type ReactEchartsProps = {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    weldingID: number;
    experimentID: number;
    messageApi: typeof AntMessage;
    theme?: "light" | "dark";
}

function ReactEchartsLinechart({option,
                                settings,
                                theme,
                                weldingID,
                                experimentID,
                                messageApi,
}: ReactEchartsProps): JSX.Element {

    const chartRef = useRef<HTMLDivElement>(null);
    const waitTillReload: number = 1200
    const dataZoomSubject = new BehaviorSubject<any>(null);
    const [linePlot, setLinePlot] = useState<LinePlotData>()
    const [labelData, setLabelData] = useState<Labels>([])
    const [selectedCategories, setSelectedCategories] = useState<number>(-1);
    const [labelDataLoading, setLabelDataLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [labelColor, setLabelColor] = useState<LabelItems>([])

    const dataZoomFunction = (dataZoomObj: any) => {
        // console.log("Zoom triggered", loadingREf.current);
        if (chartRef.current !== null && dataZoomObj !== null) {
            // console.log("Zoom activated", loadingREf.current);
            // let chart = getInstanceByDom(chartRef.current);
            const chart = getInstanceByDom(chartRef.current);
            if (chart !== undefined) {
                // console.log("dataZoom", dataZoomObj);
                let start: number;
                let end: number;
                if (dataZoomObj.hasOwnProperty("batch")) {
                    start = dataZoomObj.batch[0].start
                    end = dataZoomObj.batch[0].end
                } else if (dataZoomObj.hasOwnProperty("start") && dataZoomObj.hasOwnProperty("end")) {
                    start = dataZoomObj.start
                    end = dataZoomObj.end
                } else {
                    start = 0
                    end = 100
                }
                let zoomLevel: ZoomLevel = new ZoomLevel(start, end);
                //console.log("finished zoom", zoomLevel);

                const option: any = chart.getOption();
                let timeAxis = option.xAxis[0].data;
                let zoom = zoomLevel.convertToZoom(timeAxis)

                // chart.showLoading();
                setLoading(true);
                // loadingREf.current = true;
                let startEnd: [number, number] = zoom.getExtendedZoomIndex();
                // let startEnd: [number, number] = zoom.getZoomIndex();
                getWeldingTimeSeries(weldingID, experimentID, startEnd[0], startEnd[1]).then((data: LinePlotData | any) => {
                    setLinePlot(data);
                    chart.setOption({
                        "dataZoom": option.dataZoom
                    });
                });
            }
        }
    };

    dataZoomSubject?.pipe(
        debounceTime(waitTillReload)
    ).subscribe(dataZoomFunction)


    function downloadWeldingTimeSeries() {
        //console.log("Download New Data");
        getWeldingTimeSeries(weldingID, experimentID, undefined, undefined).then((data: LinePlotData[] | any) => {
            setLinePlot(data);
        }).catch((error: any) => {
            console.log(error);
        });
    }
        
    function downloadLabelData() {
        // console.log("labelCategory", labelCategories , "weldingID", weldingID)
        if (selectedCategories !== undefined && selectedCategories !== -1) {
            setLabelDataLoading(true);
            getLabelData(Number(weldingID), Number(experimentID), selectedCategories).then((data: Labels | any) => {
                if (data !== undefined && data !== null) {
                    setLabelData(data);
                }
                setLabelDataLoading(false);
                // console.log("Label data", data)
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
            setLabelData([]);
            downloadWeldingTimeSeries();
            downloadLabelData();
        }

        const resizeChart = () => {
            //console.log("resize");
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        chart?.on("datazoom", (event) => {
            dataZoomSubject.next(event);
        });

        chart?.setOption(option);
        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [option, settings, theme]);


    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (chart !== undefined) {
                loading ? chart.showLoading() : chart.hideLoading();
            }
        }
    }, [loading]);

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart: ECharts | undefined = getInstanceByDom(chartRef.current);
            if (linePlot !== undefined && linePlot !== null
                && chart !== undefined && chart !== null) {
                //console.log("update new line plot data");
                // console.log(linePlot);
                let option = {
                    series: linePlot.timeSeries,
                    xAxis: {
                        data: rangeArray(0, Math.max(...linePlot.timeStamp.data))
                    },
                    yAxis: [
                        {
                            position: 'left',
                            name: "voltage",
                        },
                        {
                            position: 'right',
                            name: "current",
                        },
                    ]
                }
                chart?.resize();
                chart?.setOption(option);
                setLoading(false);
                // loadingREf.current = false;
            }
        }
    }, [linePlot]);


    useEffect(() => {
        if (chartRef.current) {
            const chart = getInstanceByDom(chartRef.current);
            if (linePlot !== undefined && linePlot !== null
                && labelData !== undefined && labelData !== null
                && chart !== undefined) {
                // console.log(" ---- Map labels -----");
                // console.log(labelData);
                let labels: MarkedAreaData[] = [];

                // @ts-ignore
                for (let label of labelData) {
                    let start = label.start;
                    let end = label.end;

                    // console.log("start end", start, end);
                    if (start !== -1 && end !== -1) {

                        let selectedColorItem = labelColor.find((value, index, obj) => {  
                            return value.value === label.label;
                        });
                        
                        let selectedColor: string = "";
                        if (selectedColorItem !== undefined ) {
                            selectedColor = selectedColorItem.color;
                        }
                        // console.log(selectedColor, selectedColorItem, label)
                        labels.push([{
                            name: label.label,
                            xAxis: start,
                            itemStyle: {color: selectedColor, opacity: 0.8, silent: true}
                        }, {xAxis: end, itemStyle: {color: selectedColor, opacity: 0.8, silent: true}}]);

                    }
                }

                let options = {
                    series: [
                        {
                            markArea: {
                                data: labels
                            }
                        }
                    ]
                }
                chart.setOption(options);
            }
        }
    }, [labelData]);


    useEffect(() => {
        downloadLabelData();
    }, [selectedCategories, labelColor]);

    return (
        <div>
            <Row>
                <Col span={10}>
                    <Selector loading={labelDataLoading} callback={setSelectedCategories} />
                </Col>
                <Col span={10} offset={4}>
                    <LabelCollapse labelExperimentId={selectedCategories} labelColor={labelColor} setLabelColor={setLabelColor} messageApi={messageApi}></LabelCollapse>
                </Col>
            </Row>
            <div ref={chartRef} className="Line-chart" style={{marginLeft: "-1vw"}}></div>
        </div>
    );
}

export type {ReactEchartsProps};
export {ReactEchartsLinechart};
